import React from 'react';
import { loggedInUser } from "components/Common/Helpers.js"
import { checkRolesWithLevel, checkRoles } from 'components/Common/Helpers';
import Messaging from 'views/modules/Conversation/Messaging';
/* Client Routes */

const Dashboard = React.lazy(() => import('views/modules/ClientPortal/Dashboard/Index.js'));

const OrderListLanding = React.lazy(() => import('views/modules/ClientPortal/OrderManagement/OrderListLanding'));

const SearchLanding = React.lazy(() => import('views/modules/ClientPortal/OrderManagementSearch/SearchLanding'));
const BatchCompletionEmailLanding = React.lazy(() => import('views/modules/EpoOrderIntake/BatchCompletionEmailLanding'));
const OrderManagementSearch = React.lazy(() => import('views/modules/ClientPortal/OrderManagementSearch/SearchResult'));
const Team = React.lazy(() => import('views/modules/ClientPortal/Team/Team.js'));
const PaymentMethod = React.lazy(() => import('views/modules/ClientPortal/PaymentMethod/Index.js'));
const BorrowerPublicPaymentPage = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/BorrowerPublicPaymentPage'));
const ReportOrderPublicPaymentPage = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/ReportOrderPublicPaymentPage'));
const ChangePassword = React.lazy(() => import('views/modules/ChangePassword/index.js'));
const TransferOrderPublicPaymentPage = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/TransferOrderPublicPaymentPage.js'));
const InvoicePrint = React.lazy(() => import('views/modules/Public/InvoicePrint.js'));
const Invoice = React.lazy(() => import('views/modules/ClientPortal/Accounting/Index.js'));

const PlaceNewOrderLanding = React.lazy(() => import('views/modules/PlaceNewOrder/Landing'));
const OrderIntake = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/Index'));
const InspectionOrderIntake = React.lazy(() => import('views/modules/InspectionOrderIntake/InspectionOrderIntake'));
const EpoOrderIntake = React.lazy(() => import('views/modules/EpoOrderIntake/EpoOrderIntake'));
const EditEpoOrderIntake = React.lazy(() => import('views/modules/EpoOrderIntake/EditEpoOrderIntake'));
const EditInspectionOrderIntake = React.lazy(() => import('views/modules/InspectionOrderIntake/EditInspectionOrderIntake'));
const InspectionPaymentPublicPage = React.lazy(() => import('views/modules/InspectionOrderIntake/InspectionPaymentPublicPage'));
const Properties = React.lazy(() => import('views/modules/Properties/Properties'));
const PropertyFilePreview = React.lazy(() => import('views/modules/Properties/PropertyFilePreview'));
const ValDetails = React.lazy(() => import('views/modules/Deals/Details/ValDetails'));

const userData = loggedInUser();


var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    // icon: "ni ni-tv-2 text-primary",
    iconImage: require('./assets/img/img-icons/business-report.png').default,
    component: Dashboard,
    layout: "/client",
    is_visible: true,
  },
  {
    path: "/orders/get-batch-data/:batchId",
    name: "Avm Batch Orders",
    icon: "ni ni-bullet-list-67 text-info",
    component: OrderListLanding,
    layout: "/client",
    is_show_label: false,
    is_visible: (userData.oms_access === 1) ? true : false,
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-bullet-list-67 text-info",
    component: OrderListLanding,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
  },
  {
    path: "/invoicing",
    name: "Accounting",
    icon: "fas fa-calculator text-warning",
    component: Invoice,
    layout: "/client",
    is_visible: (userData && userData.user_id && userData.company.report_invoicing === "1" && checkRoles(['CUSTOMER_ACCOUNTING', 'MODERATOR']) ? true : false)
  },
  {
    path: "/team",
    name: "Team",
    // icon: "ni ni-circle-08 text-success",
    iconImage: require('./assets/img/img-icons/team.png').default,
    component: Team,
    layout: "/client",
    is_visible: (userData && userData.user_id && checkRolesWithLevel(['MODERATOR']) ? true : false)
  },
  {
    path: "/place-new-order",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    // iconImage: require('./assets/img/img-icons/order.png').default,
    component: PlaceNewOrderLanding,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
  },
  {
    path: "/search-property",
    name: "Search Property Results",
    icon: "ni ni-key-25 text-info",
    component: SearchLanding,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/order-intake/:intake_batch_id",
    name: "Place New Order",
    component: OrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/order-intake",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: OrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/inspection-order",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: InspectionOrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/:inspection_intake_batch_token/:company_id/edit-intake-data",
    name: "Inspection Order",
    icon: "ni ni-basket text-success",
    component: EditInspectionOrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/payment-methods",
    name: "Payment Methods",
    icon: "ni ni-credit-card text-danger",
    component: PaymentMethod,
    layout: "/client",
    is_visible: (userData.oms_access === 1 && checkRolesWithLevel(['MODERATOR'])) ? true : false,
  },
  {
    path: "/properties",
    name: "Properties",
    iconImage: require('./assets/img/img-icons/properties.png').default,
    component: Properties,
    layout: "/client",
    is_visible: false,
    is_show_label: false,
  },
  {
    path: "/property-file/:propertyId/:propertyFileId",
    name: "PropertY File",
    iconImage: require('./assets/img/img-icons/properties.png').default,
    component: PropertyFilePreview,
    layout: "/client",
    is_visible: true,
    is_show_label: false,
  },
  {
    path: "/deals",
    name: "Deals",
    icon: "fa fa-city text-primary",
    layout: "/client",
    is_visible: true,
    is_show_label: false,
    children: [
      {
        path: "/details/:propertyId",
        name: "Val Details",
        icon: "fas fa-tasks",
        component: ValDetails,
        layout: "/client",
        is_visible: true,
        is_show_label: false,
      },
    ]
  },
  {
    path: "/search",
    name: "Search Results",
    icon: "ni ni-key-25 text-info",
    component: OrderManagementSearch,
    layout: "/client",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/report-payment/:id",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: BorrowerPublicPaymentPage,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/transfer-order-payment/:id",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: TransferOrderPublicPaymentPage,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/report-order-payment/:intake_token/:company_token",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: ReportOrderPublicPaymentPage,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/inspection-payment/:intake_token",
    name: "Payment",
    icon: "ni ni-basket text-success",
    component: InspectionPaymentPublicPage,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/invoice-print/:id/:token_id",
    name: "Invoice",
    component: InvoicePrint,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-key-25 text-info",
    component: ChangePassword,
    layout: "/client",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/epo-order/:epoIntakeBatchToken/:companyId/edit-intake-data",
    name: "Edit Epo Order",
    icon: "ni ni-basket text-success",
    component: EditEpoOrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/epo-order",
    name: "Place Epo Order",
    icon: "ni ni-basket text-success",
    component: EpoOrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/batch-completion/:batchId",
    name: "Batch Completion",
    icon: "ni ni-key-25 text-info",
    component: BatchCompletionEmailLanding,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/messaging",
    name: "Messaging",
    iconImage: require('./assets/img/img-icons/messaging.png').default,
    is_show_label: true,
    component: Messaging,
    layout: "/client",
    is_visible: true
  },
];

export default routes;