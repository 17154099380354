/* ---------------------------------------COMPONENT INSTRUCTION---------------------------------------
--Import Component--
import CompanyList from "../Common/CompanyList.js";
--------------------
--Include Component--
<CompanyList isRender={true} propCurrentClient={} defaultSelection={[]} isMultiSelect={false} onHandleClientSelect={handleClient} companyQuery={} />
------------------------------------------------------------------------------------------------------*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import { PostData } from "../../../services/PostData";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as moment from "moment";


const CompanyList = ({ isRender, propCurrentClient, defaultSelection, isMultiSelect, onHandleClientSelect, companyQuery = {}, size }) => {

    let history = useHistory();
    let [clients, setClients] = useState([]);
    let [render, setRender] = useState(isRender);
    let [update, setUpdate] = useState(moment());
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (render) {
            getClients();
        }

    }, [isRender, propCurrentClient, defaultSelection, isMultiSelect, onHandleClientSelect, companyQuery]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);


    let getClients = () => {
        PostData("list/companies", { query: companyQuery }).then(result => {
            let responseJson = result.data.data;
            setClients(responseJson);

            setRender(false);
            setUpdate(moment());

        }).catch(errors => {
            console.log(errors.message);
            history.push("/auth/login");
        });
    }

    let handleSelect = (selectedClients) => {
        if (selectedClients.length) {
            let ids = selectedClients.map(function (client) {
                return client.id;
            });

            let returnData = {
                ids: ids,
                data: selectedClients
            };

            onHandleClientSelect(returnData);
            setIsDropdownOpen(false);
        } else {
            setIsDropdownOpen(true);
            onHandleClientSelect([]);
        }
    }
    const handleFocus = (e) => {
        setIsDropdownOpen(true);
        setUpdate(moment());
    };

    const handleFocusOut = () => {
        setIsDropdownOpen(false);
        setUpdate(moment());
    };

    return (
        <>
            <Typeahead
                size={size}
                clearButton
                multiple={isMultiSelect}
                defaultSelected={defaultSelection}
                id="selected-company"
                labelKey="nickname"
                onChange={(e) => handleSelect(e)}
                options={clients}
                onFocus={(e) => handleFocus(e)}
                onBlur={handleFocusOut}
                open={isDropdownOpen ? true : false}
                placeholder="Select Company"
            />
        </>
    );
};

export default CompanyList;