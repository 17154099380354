/* ---------------------------------------COMPONENT INSTRUCTION---------------------------------------
--Import Component--
import UserList from "../Common/UserList.js";
--------------------
--Include Component--
<UserList isRender={true} isMultiSelect={false} onHandleUserSelect={handleUser} userQuery={} checkUserRole=true />
------------------------------------------------------------------------------------------------------*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import { PostData } from "../../../services/PostData";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { sendExceptionEmail } from "components/Common/Helpers";
import * as moment from "moment";

const UserList = ({ isRender, defaultSelection, isMultiSelect, onHandleUserSelect, userQuery = {}, checkUserRole = true }) => {
    let history = useHistory();
    let [users, setUsers] = useState([]);
    let [render, setRender] = useState(isRender);
    let [update, setUpdate] = useState(moment());
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (render) {
            getUsers();
        }
    }, [isRender]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);

    let getUsers = async () => {
        try {
            let result = await PostData("list/get-users", { query: userQuery, params: checkUserRole })
            let responseJson = result.data.data.users;
            setUsers(responseJson);
            setRender(false);
            setUpdate(moment());
        } catch (error) {
            history.push("/auth/login");
            await sendExceptionEmail(error);
        }
    }

    let handleSelect = (selectedUsers) => {
        let ids = selectedUsers.map(function (user) {
            return user.user_id;
        });

        let returnData = {
            ids: ids,
            data: selectedUsers
        };
        setIsDropdownOpen(false);
        onHandleUserSelect(returnData);
    }

    const handleFocus = (e) => {
        setIsDropdownOpen(true);
    };

    const handleFocusOut = () => {
        setIsDropdownOpen(false);
    };

    return (
        <>
            <Typeahead
                clearButton
                multiple={isMultiSelect}
                defaultSelected={defaultSelection}
                id="selected-user"
                labelKey="full_name"
                onChange={(e) => handleSelect(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={handleFocusOut}
                open={isDropdownOpen ? true : false}
                options={users}
                placeholder="Select User"
            />
        </>
    );
};

export default UserList;