import { createSlice } from '@reduxjs/toolkit'

export const epoOrdersListReducer = createSlice({
    name: 'epoOrdersList',
    initialState: {
        counts: {
            active_payment: 0,
            inprogress: 0,
            completed: 0
        },
        currentTab: 'payments',
        currentTabIndex: 0
    },
    reducers: {
        setCount: (state, params) => {
            state.counts = params.payload.value;
        },
        setCurrentTab: (state, params) => {
            state.currentTab = params.payload.value;
        },
        setCurrentTabIndex: (state, params) => {
            state.currentTabIndex = params.payload.value;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setCount, setCurrentTab, setCurrentTabIndex } = epoOrdersListReducer.actions

export default epoOrdersListReducer.reducer