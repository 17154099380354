import React, { useState, useEffect } from "react"
import { Button, Label, Modal, Table } from "reactstrap";
import moment from "moment";
import { GoogleApiWrapper } from 'google-maps-react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

import PriceFormatter from "components/Common/PriceFormatter.js";
import NumberFormatter from "components/Common/NumberFormatter.js";
import { formatDate, loggedInUser, checkRoles, sendExceptionEmail, formatPropertyAddress } from "components/Common/Helpers.js"
import { toast } from "react-toastify";
import { showSpinner } from "ShowHideSpinner.reducer";
import { useDispatch } from "react-redux";
import CompList from "../CompLinks/CompList";
import { PostData } from "services/Api.service";
import TableSkeleton from "components/SkeletonUI/TableSkeleton";

const mapStyles = {
    width: '100%',
    height: '400px'
};
const userData = loggedInUser();

const CompListingDetailModal = ({ isCompModal, google, compData }) => {
    const dispatch = useDispatch();
    const [editCompData, setEditCompData] = useState({});
    const [propertyCompData, setPropertyCompData] = useState({});
    const [isEditAccess, setEditAccess] = useState(false);
    const [fields, setFields] = useState({ above_grade_sqft: 0, below_grade_sqft: 0 });
    const [update, setUpdate] = useState(moment());
    const [apexPropType, setApexPropType] = useState([]);
    const [isOpenModal, setModalStatus] = useState(false);
    const [isCompDataLoaded, setCompDataLoaded] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [isFetchingEditCompData, setIsFetchingEditCompData] = useState(false); // compData.showMyValues
    const isShowMyValues = compData.showMyValues ?? true;

    const current_date = new Date();
    const year = current_date.getFullYear();

    useEffect(() => {
        getCompDetails(compData)
        if (checkRoles(['ADMINISTRATOR', 'DRV_ADMIN', 'ANALYST', 'REVIEWER'])) {
            setEditAccess(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleModal(isCompModal.current);
    }, [isCompModal]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    const handleModal = (state) => {
        isCompModal.current = false;
        setModalStatus(state);
    }

    const getCompDetails = async (params) => {
        try {
            setIsFetchingEditCompData(true);
            setCompDataLoaded(false);

            const data = {
                drv_id: params.drv_id,
                property_comp_id: params.property_comp_id,
            }

            const result = await PostData("performance/getEditCompData", data);
            const responseJson = result.data;
            if (responseJson && result.success) {
                const propertyCompDataObj = responseJson.response[0];
                propertyCompDataObj.address = formatPropertyAddress(propertyCompDataObj);
                setApexPropType(responseJson.prop_types);

                const myValuesData = propertyCompDataObj.my_values;
                const reviewedData = propertyCompDataObj.reviewed;
                const drvSummaryData = reviewedData ? reviewedData.drv_summary : {};

                const mls = {
                    above_grade: propertyCompDataObj.original_living_area_square_feet || 0,
                    basement: propertyCompDataObj.original_basement_square_feet || 0,
                    beds: propertyCompDataObj.original_bedrooms_total || 0,
                    baths: propertyCompDataObj.original_bathrooms_total || 0,
                    year: propertyCompDataObj.original_year_built || 0,
                    lot: propertyCompDataObj.original_lot_size_square_feet || 0,
                    property_type: propertyCompDataObj.original_property_type_normalized || '',
                    close_price: propertyCompDataObj.original_close_price || 0,
                    stories: propertyCompDataObj.original_stories_total || 0,
                };

                const county = {
                    above_grade: propertyCompDataObj.county_pr_LivingArea || 0,
                    basement: propertyCompDataObj.county_pr_BelowGradeFinishedArea || 0,
                    beds: propertyCompDataObj.pr_BedroomsTotal || 0,
                    baths: propertyCompDataObj.pr_BathroomsTotalInteger || 0,
                    year: propertyCompDataObj.pr_YearBuilt || 0,
                    lot: propertyCompDataObj.pr_LotSizeSquareFeet || 0,
                    property_type: propertyCompDataObj.original_property_type_normalized || '-',
                    close_price: propertyCompDataObj.pr_LastSalePrice || 0,
                    stories: 0,
                };

                const reviewed = {
                    above_grade: drvSummaryData?.above_grade_sqft || 0,
                    basement: drvSummaryData?.below_grade_sqft || 0,
                    beds: drvSummaryData?.beds || 0,
                    baths: drvSummaryData?.baths || 0,
                    year: drvSummaryData?.year_built || 0,
                    lot: drvSummaryData?.lot_size || 0,
                    property_type: drvSummaryData?.property_type || '-',
                    close_price: drvSummaryData?.sale_price || 0,
                    stories: drvSummaryData?.stories || 0,
                    key_flags_water_front: drvSummaryData?.key_flags_water_front || 0,
                    key_flags_golf_course: drvSummaryData?.key_flags_golf_course || 0,
                    pool: drvSummaryData?.pool || 0,
                    garage: drvSummaryData?.garage || 0,
                    user_name: drvSummaryData?.user?.user_name || '-',
                    datetime: drvSummaryData?.datetime || '-',
                    apex_prop_type: drvSummaryData?.apex_prop_type || 0,
                };

                const myValues = compData.showMyValues ? {
                    above_grade: myValuesData?.above_grade_sqft || 0,
                    basement: myValuesData?.below_grade_sqft || 0,
                    beds: myValuesData?.beds || 0,
                    baths: myValuesData?.baths || 0,
                    year: myValuesData?.year_built || 0,
                    lot: myValuesData?.lot_size || 0,
                    property_type: myValuesData?.property_type || '-',
                    close_price: myValuesData?.sale_price || 0,
                    stories: myValuesData?.stories || 0,
                    key_flags_water_front: myValuesData?.key_flags_water_front || 0,
                    key_flags_golf_course: myValuesData?.key_flags_golf_course || 0,
                    pool: myValuesData?.pool || 0,
                    garage: myValuesData?.garage || 0,
                    user_name: myValuesData?.user?.user_name || '-',
                    datetime: myValuesData?.datetime || '-',
                } : {};

                propertyCompDataObj.task_id = propertyCompDataObj.drv_id;
                propertyCompDataObj.is_baths_discrepancies = false;
                propertyCompDataObj.is_beds_discrepancies = false;
                propertyCompDataObj.is_year_built_discrepancies = false;
                propertyCompDataObj.is_lot_size_discrepancies = false;
                propertyCompDataObj.is_main_sqft_discrepancies = false;
                propertyCompDataObj.is_finished_basement_discrepancies = false;
                propertyCompDataObj.is_total_sqft_discrepancies = false;
                propertyCompDataObj.property_comp_id = params.property_comp_id;

                // Above Grade Sqft discrepancies
                const above_grade_mls = propertyCompDataObj.original_living_area_square_feet ? propertyCompDataObj.original_living_area_square_feet : 0;
                const above_grade_pr = propertyCompDataObj.county_pr_LivingArea ? propertyCompDataObj.county_pr_LivingArea : 0;
                const above_grade_final = (above_grade_mls !== 0 && above_grade_pr !== 0) ? Math.abs(above_grade_mls - above_grade_pr) : 0;

                const min_above_grade = Math.min(above_grade_mls * 0.10, above_grade_pr * 0.10, 250);

                if (above_grade_final > min_above_grade) {
                    propertyCompDataObj.is_main_sqft_discrepancies = true;
                }

                // Below Grade Sqft discrepancies
                const below_grade_mls = propertyCompDataObj.original_basement_square_feet ? propertyCompDataObj.original_basement_square_feet : 0;
                const below_grade_pr = propertyCompDataObj.county_pr_BelowGradeFinishedArea ? propertyCompDataObj.county_pr_BelowGradeFinishedArea : 0;
                const below_grade_final = (below_grade_mls !== 0 && below_grade_pr !== 0) ? Math.abs(below_grade_mls - below_grade_pr) : 0;

                const min_below = Math.min(below_grade_mls * 0.10, below_grade_pr * 0.10, 250);
                if (below_grade_final > min_below) {
                    propertyCompDataObj.is_finished_basement_discrepancies = true;
                }

                // Total Grade Sqft discrepancies

                const total_sqft_mls = above_grade_mls + below_grade_mls;
                const total_sqft_pr = above_grade_pr + below_grade_pr;
                const total_sqft_final = (total_sqft_mls !== 0 && total_sqft_pr !== 0) ? Math.abs(total_sqft_mls - total_sqft_pr) : 0;
                const min_total = Math.min(total_sqft_mls * 0.10, total_sqft_pr * 0.10, 250);
                if (total_sqft_final > min_total) {
                    propertyCompDataObj.is_total_sqft_discrepancies = true;
                }

                // Lot discrepancies
                const lot_mls = propertyCompDataObj.original_lot_size_square_feet ? propertyCompDataObj.original_lot_size_square_feet : 0;
                const lot_pr = propertyCompDataObj.pr_LotSizeSquareFeet ? propertyCompDataObj.pr_LotSizeSquareFeet : 0;
                const lot_final = (lot_mls !== 0 && lot_pr !== 0) ? Math.abs(lot_mls - lot_pr) : 0;

                const min_l_val = Math.min(propertyCompDataObj.pr_LotSizeSquareFeet, propertyCompDataObj.original_lot_size_square_feet) * 0.10;
                if (lot_final >= min_l_val) {
                    propertyCompDataObj.is_lot_size_discrepancies = true;
                }

                // Year built discrepancies
                const year_mls = propertyCompDataObj.original_year_built ? propertyCompDataObj.original_year_built : 0;
                const year_pr = propertyCompDataObj.pr_YearBuilt ? propertyCompDataObj.pr_YearBuilt : 0;
                const year_final = (year_mls !== 0 && year_pr !== 0) ? Math.abs(year_mls - year_pr) : 0;

                if (year_final > 0) {
                    propertyCompDataObj.is_year_built_discrepancies = true;
                }

                // Beds discrepancies
                const beds_mls = propertyCompDataObj.original_bedrooms_total ? propertyCompDataObj.original_bedrooms_total : 0;
                const beds_pr = propertyCompDataObj.pr_BedroomsTotal ? propertyCompDataObj.pr_BedroomsTotal : 0;
                const beds_final = (beds_mls !== 0 && beds_pr !== 0) ? Math.abs(beds_mls - beds_pr) : 0;

                if (beds_final > 0) {
                    propertyCompDataObj.is_beds_discrepancies = true;
                }

                // baths discrepancies
                const baths_mls = propertyCompDataObj.original_bathrooms_total ? propertyCompDataObj.original_bathrooms_total : 0;
                const baths_pr = propertyCompDataObj.pr_BathroomsTotalInteger ? propertyCompDataObj.pr_BathroomsTotalInteger : 0;
                const baths_final = (baths_mls !== 0 && baths_pr !== 0) ? Math.abs(baths_mls - baths_pr) : 0;

                if (baths_final > 0) {
                    propertyCompDataObj.is_baths_discrepancies = true;
                }
                populatePropertyForm(reviewed);
                setPropertyCompData(propertyCompDataObj);
                const editDataObj = { mls, county, reviewed, my_values: myValues };
                setEditCompData(editDataObj);
                setCompDataLoaded(true);
            }
            setIsFetchingEditCompData(false);
        } catch (errors) {
            setIsFetchingEditCompData(false);
            await sendExceptionEmail(errors)
            toast["error"]("Something went wrong.");
        };
    }

    const populatePropertyForm = async (reviewedData) => {
        try {
            fields.above_grade_sqft = reviewedData.above_grade ? reviewedData.above_grade : '0';
            fields.below_grade_sqft = reviewedData.basement ? reviewedData.basement : '0';
            fields.total_sqft = (reviewedData.above_grade) + (reviewedData.basement);
            fields.beds = reviewedData.beds ? reviewedData.beds : '0.00';
            fields.baths = reviewedData.baths ? reviewedData.baths : '0.00';
            fields.year_built = reviewedData.year;
            fields.stories = reviewedData.stories ? reviewedData.stories : '0';
            fields.lot_size = reviewedData.lot ? reviewedData.lot : '0';
            fields.property_type = reviewedData.property_type ? reviewedData.property_type : "";
            fields.apex_prop_type = reviewedData.apex_prop_type ? reviewedData.apex_prop_type : "";
            fields.pool = reviewedData.pool ? reviewedData.pool : '0';
            fields.garage = reviewedData.garage ? reviewedData.garage : '0';
            fields.key_flags_golf_course = reviewedData.key_flags_golf_course ? reviewedData.key_flags_golf_course : '0';
            fields.key_flags_water_front = reviewedData.key_flags_water_front ? reviewedData.key_flags_water_front : '0';
            setFields(fields);
        } catch (error) {
            await sendExceptionEmail(error)
            toast["error"]("Something went wrong.");
        }
    }

    const handleInput = async (e) => {
        try {
            const fieldsTemp = fields;
            let { value, name, checked, type } = e.target;

            if (type === "checkbox") {
                value = checked ? 1 : 0;
            }
            let tempValue = {};
            if (name === 'property_type') {
                if (apexPropType) {
                    apexPropType.map((obj, key) => {
                        if (obj.property_type_normalized === value) {
                            tempValue = obj;
                        }
                        return tempValue;
                    })
                }
                fieldsTemp['apex_prop_type'] = Object.keys(tempValue).length > 0 ? tempValue.apex_prop_type : "";
                fieldsTemp[name] = Object.keys(tempValue).length > 0 ? tempValue.property_type_normalized : "";
            } else {
                fieldsTemp[name] = value;
            }
            setFields(fieldsTemp)
            setUpdate(moment());
        } catch (error) {
            await sendExceptionEmail(error)
            toast["error"]("Something went wrong.");
        }
    }

    // Save and update property comp data
    const handlePropertySubmit = async (e) => {
        e.preventDefault();
        try {
            const data = fields;
            data.user_id = userData.user_id;
            data.company_id = userData.company_id;
            data.property_comp_id = propertyCompData.property_comp_id;
            data.reconciliation_type = 2;
            data.isFinalValue = 1;
            dispatch(showSpinner(true));
            const result = await PostData("orders/save_update_discrepancy_reconciliation_values", data)
            if (result.status === 200) {
                toast['success'](result.message);
            } else {
                toast['error'](result.message);
            }
            dispatch(showSpinner(false));
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
        }
    }

    //  Map box close
    const onClose = props => {
        if (showingInfoWindow) {
            setActiveMarker(null);
            setShowingInfoWindow(true);
        }
    };

    const handleLotSizeError = (e) => {
        if (fields.property_type === "Condo") {
            return 0;
        } else {
            return 500;
        }
    }

    // Map box
    const onMarkerClick = (props, marker, e) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    }

    const propDataForCompList = {
        propertyImgs: propertyCompData.image_urls,
        image_urls: propertyCompData.image_urls,
        public_remarks: propertyCompData.public_remarks,
        address: propertyCompData.address,
        city: propertyCompData.city,
        state: propertyCompData.state,
        postal_code: propertyCompData.postal_code,
        customTabListStyle: { padding: "6px 26px" },
        contextMenu: true,
        google: google,
        zoom: 20,
        mapStyles,
        latitude: propertyCompData.latitude,
        longitude: propertyCompData.longitude,
        onMarkerClick: onMarkerClick,
        markerName: propertyCompData.address + ", " + propertyCompData.city + ", " + propertyCompData.state + ", " + propertyCompData.postal_code,
        infoWindowMarker: activeMarker,
        infoWindowVisible: showingInfoWindow,
        infoWindowOnClose: onClose,
        selectedPlace,
        openModel: isOpenModal,
        isFromSubjectPropertyCondition: false,
    }

    return (
        <Modal className="modal-dialog-centered discrepancy-property-model" style={{ width: "99%", maxWidth: "98%", height: "90%", maxHeight: "90%" }} isOpen={isOpenModal} toggle={() => handleModal(false)}>
            <div className="modal-header modal-header-colored">
                <h2 className="modal-title w-100 text-center" id="exampleModalLabel">
                    DISCREPANCY COMP
                </h2>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleModal(false)}>
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {isFetchingEditCompData ?
                <TableSkeleton rows={10} cols={15} />
                :
                <div className="modal-body text-center">
                    <div className="row">
                        <div className="col-md-3 row-mls-section">
                            <h2>Edit Comp Details:{propertyCompData.address}</h2>
                            <hr />
                            <div className="discrepancy-column-body">
                                <Table className="text-left property-comp-table" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" width="45%">&nbsp;</th>
                                            <th scope="col">
                                                <div className="custom-control custom-control-alternative custom-radio">
                                                    Raw MLS
                                                </div>
                                            </th>

                                            <th scope="col" width="20%">
                                                <div className="custom-control custom-control-alternative custom-radio">
                                                    Public Records
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><Label>Closed Price</Label></td>
                                            <td><PriceFormatter price={("mls" in editCompData) ? editCompData.mls.close_price : 0} /></td>
                                            <td><PriceFormatter price={("county" in editCompData) ? editCompData.county.close_price : 0} /></td>
                                        </tr>
                                        <tr className={propertyCompData.is_main_sqft_discrepancies === true ? "difference" : null}>
                                            <td><Label>Main Sqft</Label></td>
                                            <td><NumberFormatter number={("mls" in editCompData) ? editCompData.mls.above_grade : 0} /></td>
                                            <td><NumberFormatter number={("county" in editCompData) ? editCompData.county.above_grade : 0} /></td>
                                        </tr>
                                        <tr className={propertyCompData.is_finished_basement_discrepancies === true ? "difference" : null}>
                                            <td><Label>Finished Basement</Label></td>
                                            <td><NumberFormatter number={("mls" in editCompData) ? editCompData.mls.basement : 0} /></td>
                                            <td><NumberFormatter number={("county" in editCompData) ? editCompData.county.basement : 0} /></td>
                                        </tr>
                                        <tr className={propertyCompData.is_total_sqft_discrepancies === true ? "difference" : null}>
                                            <td><Label>Total Sqft</Label></td>
                                            <td><NumberFormatter number={("mls" in editCompData) ? editCompData.mls.above_grade + editCompData.mls.basement : 0} /></td>
                                            <td><NumberFormatter number={("county" in editCompData) ? editCompData.county.above_grade + editCompData.county.basement : 0} /></td>
                                        </tr>
                                        <tr className={propertyCompData.is_beds_discrepancies === true ? "difference" : null}>
                                            <td><Label>Beds</Label></td>
                                            <td>{("mls" in editCompData) ? editCompData.mls.beds : 0}</td>
                                            <td>{("county" in editCompData) ? editCompData.county.beds : 0}</td>
                                        </tr>
                                        <tr className={propertyCompData.is_baths_discrepancies === true ? "difference" : null}>
                                            <td><Label>Bath Rooms</Label></td>
                                            <td >{("mls" in editCompData) ? parseFloat(editCompData.mls.baths).toFixed(2) : "0.00"}</td>
                                            <td>{("county" in editCompData) ? parseFloat(editCompData.county.baths).toFixed(2) : "0.00"}</td>
                                        </tr>
                                        <tr className={propertyCompData.is_year_built_discrepancies === true ? "difference" : null}>
                                            <td><Label>Year Built</Label></td>
                                            <td>{("mls" in editCompData) ? editCompData.mls.year : 0}</td>
                                            <td>{("county" in editCompData) ? editCompData.county.year : 0}</td>
                                        </tr>
                                        <tr>
                                            <td><Label>Stories</Label></td>
                                            <td>{("mls" in editCompData) ? editCompData.mls.stories : 0}</td>
                                            <td>{("county" in editCompData) ? editCompData.county.stories : 0}</td>
                                        </tr>
                                        <tr className={propertyCompData.is_lot_size_discrepancies === true ? "difference" : null}>
                                            <td><Label>Lot Size (sqft)</Label></td>
                                            <td>{("mls" in editCompData) ? editCompData.mls.lot : 0}</td>
                                            <td>{("county" in editCompData) ? editCompData.county.lot : 0}</td>
                                        </tr>
                                        <tr>
                                            <td><Label>Property Type</Label></td>
                                            <td>{("mls" in editCompData) ? editCompData.mls.property_type : "-"}</td>
                                            <td>{("county" in editCompData) ? editCompData.county.property_type : "-"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="col-md-6 middle-section">
                            <h2>&nbsp;</h2>
                            <hr />
                            {isCompDataLoaded && <CompList props={propDataForCompList} />}
                        </div>
                        <div className="col-md-3 discrepancy-section p-0">
                            <h2 className="comp-data">Comp ID <span>{propertyCompData ? propertyCompData.comp_id : "####"}</span>, Discrepancy ID <span>{propertyCompData ? propertyCompData.task_id : "####"}</span></h2>
                            <hr />
                            <div className="comp-column-body">
                                <AvForm onValidSubmit={(e) => handlePropertySubmit(e)} >
                                    <Table className="text-left property-comp-table" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" width={isEditAccess ? '28%' : '34%'}>&nbsp;</th>
                                                {isShowMyValues &&
                                                    <th scope="col" width={isEditAccess ? '22%' : '33%'}>
                                                        <div className="custom-control custom-control-alternative custom-radio">
                                                            My values
                                                        </div>
                                                    </th>
                                                }

                                                <th scope="col" width={isEditAccess ? '22%' : '33%'}>
                                                    <div className="custom-control custom-control-alternative custom-radio">
                                                        Reviewed
                                                    </div>
                                                </th>
                                                {isEditAccess && <th scope="col" width="28%">
                                                    <div className="custom-control custom-control-alternative custom-radio">
                                                        New Values
                                                    </div>
                                                </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Label>User</Label></td>
                                                {isShowMyValues && <td>{"my_values" in editCompData ? (editCompData.my_values.user_name) : ''}</td>}
                                                <td>{"reviewed" in editCompData ? (editCompData.reviewed.user_name) : ''}</td>
                                                {isEditAccess && <td>&nbsp;</td>}
                                            </tr>
                                            <tr>
                                                <td><Label>Date Time</Label></td>
                                                {isShowMyValues && <td>{"my_values" in editCompData ? formatDate(null, editCompData.my_values.datetime, "MMM D h:mm a") : ''}</td>}
                                                <td>{"reviewed" in editCompData ? formatDate(null, editCompData.reviewed.datetime, "MMM D h:mm a") : ''}</td>
                                                {isEditAccess && <td>&nbsp;</td>}
                                            </tr>

                                            <tr>
                                                <td><Label>Closed Price</Label></td>
                                                {isShowMyValues && <td><PriceFormatter price={"my_values" in editCompData ? (editCompData.my_values.close_price) : 0} /></td>}
                                                <td><PriceFormatter price={"reviewed" in editCompData ? (editCompData.reviewed.close_price) : 0} /></td>
                                                {isEditAccess && <td>&nbsp;</td>}
                                            </tr>
                                            <tr>
                                                <td><Label>Main Sqft</Label></td>
                                                {isShowMyValues && <td><NumberFormatter number={"my_values" in editCompData ? (editCompData.my_values.above_grade) : 0} /></td>}
                                                <td><NumberFormatter number={"reviewed" in editCompData ? (editCompData.reviewed.above_grade) : 0} /></td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="above_grade_sqft"
                                                                onChange={(event) => handleInput(event)}
                                                                className="form-control-alternative form-control"
                                                                value={fields.above_grade_sqft}
                                                                placeholder=""
                                                                type="text"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage: "This field is required."
                                                                    },
                                                                    number: {
                                                                        value: true,
                                                                        errorMessage: "Enter only number value."
                                                                    },
                                                                    min: {
                                                                        value: 450,
                                                                        errorMessage: "This value should be between 450 and 15000."
                                                                    },
                                                                    max: {
                                                                        value: 15000,
                                                                        errorMessage: "This value should be between 450 and 15000."
                                                                    }
                                                                }}
                                                            />
                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Finished Basement</Label></td>
                                                {isShowMyValues && <td><NumberFormatter number={"my_values" in editCompData ? (editCompData.my_values.basement) : 0} /></td>}
                                                <td><NumberFormatter number={"reviewed" in editCompData ? (editCompData.reviewed.basement) : 0} /></td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="below_grade_sqft"
                                                                onChange={(event) => handleInput(event)}
                                                                className="form-control-alternative form-control"
                                                                value={fields.below_grade_sqft}
                                                                placeholder=""
                                                                type="text"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage: "This field is required."
                                                                    },
                                                                    number: {
                                                                        value: true,
                                                                        errorMessage: "Enter only number value."
                                                                    }
                                                                }}
                                                            />

                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Total Sqft</Label></td>
                                                {isShowMyValues && <td><NumberFormatter number={"my_values" in editCompData ? (editCompData.my_values.above_grade + editCompData.my_values.basement) : 0} /></td>}
                                                <td><NumberFormatter number={"reviewed" in editCompData ? (editCompData.reviewed.above_grade + editCompData.reviewed.basement) : 0} /></td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="total_sqft"
                                                                onChange={(event) => handleInput(event)}
                                                                className="form-control-alternative form-control disabled-input"
                                                                value={(parseFloat(fields.above_grade_sqft) + parseFloat(fields?.below_grade_sqft)) >= 0 ? (parseFloat(fields?.above_grade_sqft) + parseFloat(fields?.below_grade_sqft)).toFixed(0) : ""}
                                                                placeholder=""
                                                                type="text"
                                                                disabled
                                                            />

                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Beds</Label></td>
                                                {isShowMyValues && <td>{"my_values" in editCompData ? (editCompData.my_values.beds) : 0}</td>}
                                                <td>{"reviewed" in editCompData ? (editCompData.reviewed.beds) : 0}</td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="beds"
                                                                onChange={(event) => handleInput(event)}
                                                                className="form-control-alternative form-control"
                                                                value={fields.beds}
                                                                placeholder=""
                                                                type="text"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage: "This field is required."
                                                                    },
                                                                    number: {
                                                                        value: true,
                                                                        errorMessage: "Enter only number value."
                                                                    },
                                                                    pattern: {
                                                                        value: '^[0-9\b]+$',
                                                                        errorMessage: 'Enter only number value, decimal value not allowed.'
                                                                    },
                                                                    min: {
                                                                        value: 0,
                                                                        errorMessage: `This value is not valid.`
                                                                    },
                                                                    max: {
                                                                        value: 16,
                                                                        errorMessage: `This value should be less than or equal to 16.`
                                                                    }
                                                                }}
                                                            />

                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Bath Rooms</Label></td>
                                                {isShowMyValues && <td >{"my_values" in editCompData ? (editCompData.my_values.baths) : "0.00"}</td>}
                                                <td>{"reviewed" in editCompData ? (editCompData.reviewed.baths) : "0.00"}</td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="baths"
                                                                onChange={(event) => handleInput(event)}
                                                                className="form-control-alternative form-control"
                                                                value={fields.baths}
                                                                placeholder=""
                                                                type="text"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage: "This field is required."
                                                                    },
                                                                    number: {
                                                                        value: true,
                                                                        errorMessage: "Enter only number value."
                                                                    },
                                                                    min: {
                                                                        value: 1,
                                                                        errorMessage: `This value should be between 1 and 16.`
                                                                    },
                                                                    max: {
                                                                        value: 16,
                                                                        errorMessage: `This value should be between 1 and 16.`
                                                                    }
                                                                }}
                                                            />

                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Year Built</Label></td>
                                                {isShowMyValues && <td>{"my_values" in editCompData ? (editCompData.my_values.year) : 0}</td>}
                                                <td>{"reviewed" in editCompData ? (editCompData.reviewed.year) : 0}</td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="year_built"
                                                                onChange={(event) => handleInput(event)}
                                                                className="form-control-alternative form-control"
                                                                value={fields.year_built}
                                                                placeholder=""
                                                                type="text"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage: "This field is required."
                                                                    },
                                                                    number: {
                                                                        value: true,
                                                                        errorMessage: "Enter only number value."
                                                                    },
                                                                    pattern: {
                                                                        value: '^[0-9\b]+$',
                                                                        errorMessage: 'Enter only number value, decimal value not allowed.'
                                                                    },
                                                                    min: {
                                                                        value: 1701,
                                                                        errorMessage: `This value should be between 1701 and ${year}.`
                                                                    },
                                                                    max: {
                                                                        value: year,
                                                                        errorMessage: `This value should be between 1701 and ${year}.`
                                                                    }
                                                                }}
                                                            />

                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Stories</Label></td>
                                                {isShowMyValues && <td>{"my_values" in editCompData ? (editCompData.my_values.stories) : 0}</td>}
                                                <td>{"reviewed" in editCompData ? (editCompData.reviewed.stories) : 0}</td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="stories"
                                                                onChange={(event) => handleInput(event)}
                                                                className="form-control-alternative form-control"
                                                                value={fields.stories}
                                                                placeholder=""
                                                                type="text"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage: "This field is required."
                                                                    },
                                                                    number: {
                                                                        value: true,
                                                                        errorMessage: "Enter only number value."
                                                                    },
                                                                    min: {
                                                                        value: 1,
                                                                        errorMessage: `This value should be between 1 and 4.`
                                                                    },
                                                                    max: {
                                                                        value: 4,
                                                                        errorMessage: `This value should be between 1 and 4.`
                                                                    }
                                                                }}
                                                            />

                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Lot Size (sqft)</Label></td>
                                                {isShowMyValues && <td>{"my_values" in editCompData ? (editCompData.my_values.lot) : 0}</td>}
                                                <td>{"reviewed" in editCompData ? (editCompData.reviewed.lot) : 0}</td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="lot_size"
                                                                onChange={(event) => handleInput(event)}
                                                                className="form-control-alternative form-control"
                                                                value={fields.lot_size}
                                                                placeholder=""
                                                                type="text"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage: "This field is required."
                                                                    },
                                                                    number: {
                                                                        value: true,
                                                                        errorMessage: "Enter only number value."
                                                                    },
                                                                    min: {
                                                                        value: handleLotSizeError(),
                                                                        errorMessage: `This value should be between ${handleLotSizeError()} and 5000000.`
                                                                    },
                                                                    max: {
                                                                        value: 5000000,
                                                                        errorMessage: `This value should be between ${handleLotSizeError()} and 500000.`
                                                                    }
                                                                }}
                                                            />
                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Garage</Label></td>
                                                {isShowMyValues && <td>{"my_values" in editCompData ? (editCompData.my_values.garage) : 0}</td>}
                                                <td>{"reviewed" in editCompData && editCompData.reviewed.garage ? (editCompData.reviewed.garage) : 0}</td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField name="garage" onChange={(event) => handleInput(event)} className="form-control-alternative form-control disabled-input" value={fields.garage ? fields.garage : 0} placeholder="" type="text" validate={{
                                                                required: { value: true, errorMessage: "This field is required." }, number: { value: true, errorMessage: "Enter only number value." },
                                                                min: { value: 0, errorMessage: "This value should be between 0 and 10." },
                                                                max: { value: 10, errorMessage: "This value should be between 0 and 10." }
                                                            }} />
                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Water Front</Label></td>
                                                {isShowMyValues &&
                                                    <td>
                                                        <div color="info" className="custom-checkbox-container inline" sm="2">
                                                            <label className="checkbox-div">
                                                                <input type="checkbox" readOnly name="my_key_flags_water_front" checked={"my_values" in editCompData && parseInt(editCompData.my_values.key_flags_water_front) === 1 ? true : false}
                                                                    onChange={(event) => { }}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                }
                                                <td>
                                                    <div color="info" className="custom-checkbox-container inline" sm="2">
                                                        <label className="checkbox-div">
                                                            <input type="checkbox" readOnly name="reviewed_key_flags_water_front" checked={"reviewed" in editCompData && editCompData.reviewed.key_flags_water_front && parseInt(editCompData.reviewed.key_flags_water_front) === 1 ? true : false}
                                                                onChange={(event) => { }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </td>
                                                {isEditAccess &&
                                                    <td>
                                                        <div color="info" className="custom-checkbox-container inline" sm="2">
                                                            <label className="checkbox-div">
                                                                <input type="checkbox" name="key_flags_water_front" checked={fields.key_flags_water_front && parseInt(fields.key_flags_water_front) === 1 ? true : false}
                                                                    onChange={(event) => handleInput(event)}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Golf Course</Label></td>
                                                {isShowMyValues &&
                                                    <td>
                                                        <div color="info" className="custom-checkbox-container inline" sm="2">
                                                            <label className="checkbox-div">
                                                                <input type="checkbox" readOnly name="my_key_flags_golf_course" checked={"my_values" in editCompData && editCompData.my_values.key_flags_golf_course && parseInt(editCompData.my_values.key_flags_golf_course) === 1 ? true : false}
                                                                    onChange={(event) => { }}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                }
                                                <td>
                                                    <div color="info" className="custom-checkbox-container inline" sm="2">
                                                        <label className="checkbox-div">
                                                            <input type="checkbox" readOnly name="reviewed_key_flags_golf_course" checked={"reviewed" in editCompData && editCompData.reviewed.key_flags_golf_course && parseInt(editCompData.reviewed.key_flags_golf_course) === 1 ? true : false}
                                                                onChange={(event) => { }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </td>
                                                {isEditAccess &&
                                                    <td>
                                                        <div color="info" className="custom-checkbox-container inline" sm="2">
                                                            <label className="checkbox-div">
                                                                <input type="checkbox" name="key_flags_golf_course" checked={fields.key_flags_golf_course && parseInt(fields.key_flags_golf_course) === 1 ? true : false}
                                                                    onChange={(event) => handleInput(event)}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Pool</Label></td>
                                                {isShowMyValues &&
                                                    <td>
                                                        <div color="info" className="custom-checkbox-container inline" sm="2">
                                                            <label className="checkbox-div">
                                                                <input type="checkbox" readOnly name="my_pool" checked={"my_values" in editCompData && editCompData.my_values.pool && parseInt(editCompData.my_values.pool) === 1 ? true : false}
                                                                    onChange={(event) => { }}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                }
                                                <td>
                                                    <div color="info" className="custom-checkbox-container inline" sm="2">
                                                        <label className="checkbox-div">
                                                            <input type="checkbox" readOnly name="reviewed_pool" checked={"reviewed" in editCompData && editCompData.reviewed.pool && parseInt(editCompData.reviewed.pool) === 1 ? true : false}
                                                                onChange={(event) => { }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </td>
                                                {isEditAccess &&
                                                    <td>
                                                        <div color="info" className="custom-checkbox-container inline" sm="2">
                                                            <label className="checkbox-div">
                                                                <input type="checkbox" name="pool" checked={fields.pool && parseInt(fields.pool) === 1 ? true : false}
                                                                    onChange={(event) => handleInput(event)}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                            <tr>
                                                <td><Label>Property Type</Label></td>
                                                {isShowMyValues && <td>{"my_values" in editCompData && editCompData.my_values.property_type ? (editCompData.my_values.property_type) : 0}</td>}
                                                <td>{"reviewed" in editCompData && editCompData.reviewed.property_type ? (editCompData.reviewed.property_type) : 0}</td>
                                                {isEditAccess &&
                                                    <td>
                                                        <AvGroup>
                                                            <AvField
                                                                name="property_type"
                                                                className="form-control-alternative form-control p-0"
                                                                placeholder=""
                                                                value={fields.property_type}
                                                                type="select"
                                                                onChange={(event) => handleInput(event)}
                                                            >
                                                                <option>Property Type</option>
                                                                {apexPropType ? apexPropType.map((e, key) => {
                                                                    return <option key={key} value={e.property_type_normalized}>{e.property_type_normalized}</option>
                                                                }) : null}

                                                            </AvField>
                                                        </AvGroup>
                                                    </td>
                                                }
                                            </tr>
                                            {isEditAccess && <tr>
                                                <td colSpan={4}>
                                                    <div className="text-right mt-2 col-md-12">
                                                        <Button size="sm" color="info">
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </AvForm>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="modal-footer"></div>
        </Modal>
    )
}

export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(CompListingDetailModal);