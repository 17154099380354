import React, { useEffect, useState } from "react";
import { Container, Row, Card, CardBody, Col, Button } from "reactstrap";
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import CompanyList from "../Common/CompanyList.js";
import Header from "components/Headers/Header.js";
import * as moment from "moment";
import { GetData as OrderIntakeGet } from "services/OrderIntakeData";
import { GetData, PostData } from "services/Api.service";
import { sendExceptionEmail, formatPropertyAddress } from "components/Common/Helpers";
import { Typeahead } from "react-bootstrap-typeahead";

const PaymentMethod = () => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [cardPaymentSources, setCardPaymentSources] = useState([]);
    const [singleUsedCardPaymentSources, setSingleUsedCardPaymentSources] = useState([]);
    const [reportOrders, setReportOrders] = useState([]);
    const [inspectionOrders, setInspectionOrders] = useState([]);

    const [update, setUpdate] = useState(moment());
    const [client, setClient] = useState(null);
    const [company_query] = useState({ oms_active: '1' });
    const [data, setData] = useState({ applyCCSurcharge: true });

    useEffect(() => {
        // returned function will be called on component unmount 
        return () => {
            setClient(null);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // fetchPaymentSources();
    }, [update]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (client) {
            setData({ applyCCSurcharge: true })
            fetchAllData();
        }
    }, [client]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchAllData = async () => {
        try {
            setIsProcessing(true);
            await fetchPaymentSources();
            await fetchReportOrders();
            await fetchInspectionOrders();
            setIsProcessing(false);
        } catch (error) {
            toast["error"](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setIsProcessing(false);
        }
    };

    /*
    | Fetch all payment sources
    */
    const fetchPaymentSources = async () => {
        try {
            setCardPaymentSources([]);
            setSingleUsedCardPaymentSources([]);
            const response = await OrderIntakeGet("company/payment-sources", { company_token: "{company_token}", include_single_used_card: '1' }, client.id)
            const responseData = response.data;

            if (responseData) {
                if (responseData.paymentSources && responseData.paymentSources.stripe) {
                    const arr = [...responseData.paymentSources.stripe.ach, ...responseData.paymentSources.stripe.creditCards];
                    const multiUsedCardPaymentSourcesTemp = arr.map((ele) => {
                        return {
                            id: ele.payment_source_id,
                            label: `${ele?.source_name} ending with **** ${ele.last_4}`
                        }
                    });
                    setCardPaymentSources(multiUsedCardPaymentSourcesTemp);

                    const singleUsedCardPaymentSourcesTemp = responseData.paymentSources.stripe.singleUsedCards.map((ele) => {
                        return {
                            id: ele.payment_source_id,
                            label: `${ele?.source_name} ending with **** ${ele.last_4}`
                        }
                    });
                    setSingleUsedCardPaymentSources(singleUsedCardPaymentSourcesTemp);
                }
            }
        } catch (error) {
            throw error;
        }
    };

    const fetchReportOrders = async () => {
        try {
            setReportOrders([]);
            const response = await GetData("orders/company-report-orders", { companyToken: client.token })

            const reportOrderTemp = response.data?.reportOrders?.map((ele) => {
                return {
                    id: ele.id,
                    order_token: ele.order_token,
                    label: `${ele?.report_type?.nickname} , ${formatPropertyAddress(ele.property)}`
                }
            });

            setReportOrders(reportOrderTemp);
        } catch (error) {
            throw error;
        }
    };

    const fetchInspectionOrders = async () => {
        try {
            setInspectionOrders([]);
            const response = await GetData("inspection/company-inspection-orders", { companyToken: client.token })

            const inspectionOrderTemp = response.data?.inspectionOrders?.map((ele) => {
                const property = ele.property || ele.inspection_property
                return {
                    id: ele.id,
                    inspection_order_token: ele.inspection_order_token,
                    label: `${ele?.inspection_type?.name} , ${formatPropertyAddress(property)}`
                }
            });
            setInspectionOrders(inspectionOrderTemp);
        } catch (error) {
            throw error;
        }
    };

    const handleClient = (response) => {
        if (response && response?.ids?.length) {
            setClient(response.data[0]);
            setUpdate(moment());
        } else {
            setClient(null);
            setUpdate(moment());
        }
    };

    const handleReassignProcess = async () => {
        try {
            setIsProcessing(true);

            let params = {
                companyToken: client.token,
                amountToCharge: data.amountToCharge,
                description: data.description,
                applyCCSurcharge: data?.applyCCSurcharge ? '1' : '0'
            };

            if (data?.singleUsedPaymentSourceId || data?.multiUsedPaymentSourceId) {
                params.paymentSourceId = data?.singleUsedPaymentSourceId || data?.multiUsedPaymentSourceId
            }
            if (data?.reportOrderToken) {
                params.reportOrderToken = data?.reportOrderToken;
            }
            if (data?.inspectionOrderToken) {
                params.inspectionOrderToken = data?.inspectionOrderToken;
            }

            const resp = await PostData("company/misc-charge", params);

            setIsProcessing(false);
            setData({ applyCCSurcharge: true })
            setClient(null);
            toast['success'](resp.message);
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setIsProcessing(false);
        };
    };

    const handleOnChange = async (e) => {
        try {
            const { name, value, checked } = e.target;
            let temp = {}

            if (name === 'applyCCSurcharge') {
                temp[name] = checked
            } else {
                temp[name] = value
            }
            setData(prevReq => {
                return { ...prevReq, ...temp };
            });
        } catch (error) {
            toast['error'](error.message);
            await sendExceptionEmail(error)
        }
    };

    return (
        <>
            <Header />
            <Container className="mt--9 container-vh-100 payment-method" fluid>
                <Card className="shadow">
                    <CardBody>
                        <Row className="mb-3">
                            <Col xs="12" sm="12" md="12" lg="12">
                                <Row className="mb-3">
                                    <Col xs="12" sm="12" md="12" lg="6">
                                        <AvForm onValidSubmit={(e) => handleReassignProcess()} >
                                            <Row>
                                                <Col md="6" className="mb-4">
                                                    <label className="required">Client</label>
                                                    <CompanyList size="sm" isRender={true} defaultSelection={[]} isMultiSelect={false} onHandleClientSelect={handleClient} companyQuery={company_query} />
                                                </Col>
                                            </Row>

                                            {client &&
                                                <>
                                                    <Row>
                                                        <Col md="6">
                                                            <label className="">Company Cards/Bank Accounts</label>
                                                            <Typeahead
                                                                size="sm" clearButton id="multiUsedPaymentSourceId" labelKey="label"
                                                                disabled={data?.singleUsedPaymentSourceId ? true : false} options={cardPaymentSources} placeholder="Select Report Order"
                                                                onChange={(value) => {
                                                                    const temp = { multiUsedPaymentSourceId: value?.[0]?.id || null }
                                                                    setData(prevReq => { return { ...prevReq, ...temp }; });
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md="6">
                                                            <label className="">Single Used Cards</label>
                                                            <Typeahead
                                                                size="sm" clearButton id="singleUsedPaymentSourceId" labelKey="label"
                                                                disabled={data?.multiUsedPaymentSourceId ? true : false} options={singleUsedCardPaymentSources} placeholder="Select Report Order"
                                                                onChange={(value) => {
                                                                    const temp = { singleUsedPaymentSourceId: value?.[0]?.id || null }
                                                                    setData(prevReq => { return { ...prevReq, ...temp }; });
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md="6" className="mt-4">
                                                            <label>Report Order</label>
                                                            <Typeahead
                                                                size="sm" clearButton id="selectedReportOrder" labelKey="label" disabled={data?.inspectionOrderToken ? true : false} options={reportOrders} placeholder="Select Report Order"
                                                                onChange={(value) => {
                                                                    const temp = { reportOrderToken: value?.[0]?.order_token || null }
                                                                    setData(prevReq => { return { ...prevReq, ...temp }; });
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md="6" className="mt-4">
                                                            <label>Inspection Order</label>
                                                            <Typeahead size="sm" clearButton id="selectedInspectionOrder" labelKey="label" disabled={data?.reportOrderToken ? true : false} options={inspectionOrders} placeholder="Select Inspection Order"
                                                                onChange={(value) => {
                                                                    const temp = { inspectionOrderToken: value?.[0]?.inspection_order_token || null }
                                                                    setData(prevReq => { return { ...prevReq, ...temp }; });
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md="12" className="">
                                                            <Row>
                                                                <small className="col-md-12 text-muted font-weight-bold ">
                                                                    * Select a report or inspection order if the charge should be applied to it (Optional).
                                                                </small >
                                                            </Row>
                                                        </Col>
                                                        <Col md="6" className="mt-4">
                                                            <label className="required">Amount to Charge</label>
                                                            <AvField
                                                                bsSize="sm"
                                                                name="amountToCharge"
                                                                required onChange={handleOnChange}
                                                                validate={{
                                                                    pattern: { value: /^\$?(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/, errorMessage: 'Invalid Amount' },
                                                                    min: { value: 10, errorMessage: `Amount must be greater than or equal to 10` },
                                                                    max: { value: 1500, errorMessage: `Amount must be less than or equal to 1500` }
                                                                }}
                                                            />
                                                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                                                <input
                                                                    className="custom-control-input"
                                                                    name="applyCCSurcharge"
                                                                    id="applyCCSurcharge"
                                                                    type="checkbox"
                                                                    value='1'
                                                                    onChange={(e) => handleOnChange(e)}
                                                                    checked={(data?.applyCCSurcharge)}
                                                                />
                                                                <label className="custom-control-label" htmlFor="applyCCSurcharge">
                                                                    Apply CC surcharge fee
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        <Col md="6" className="mt-4">
                                                            <label className="required">Description</label>
                                                            <AvField bsSize="sm" type="textarea" name="description" required onChange={handleOnChange} />
                                                        </Col>

                                                        <Col xs="12" className="text-right mt-2">
                                                            {isProcessing ?
                                                                <Button type="button" color="warning" size="sm">
                                                                    <div className="fa fa-spinner fa-spin"></div> Processing...
                                                                </Button>
                                                                :
                                                                <Button size="sm" color="primary"> Submit </Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </AvForm>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

export default PaymentMethod;