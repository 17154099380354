import React, { useEffect } from "react";
import { Button, Card, Col, FormGroup, Label, Modal, Row, UncontrolledTooltip } from "reactstrap";
import Spinner from "components/Common/Spinner";
import { useState } from "react";
import * as moment from "moment";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import RenderActionButton from "../ActionsButtons/RenderActionButton";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import { PostData, GetData } from "services/Api.service";
import { toast } from "react-toastify";
import { formatDate, priceNumber, number_format, sendExceptionEmail, loggedInUser, getConditionRatingForNewComp, truncate, getConditionRatingColor, AutoLogin, convertLotSize, checkRoles, checkRolesWithLevel, capitalizeFirstLetter, ConditionRatingsFormate } from "components/Common/Helpers";
import { Map as GMap, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import ToggleSwitch from "../ToggleSwitch";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import eventBus from "../EventBus";
import axios from "axios";
import ValuationReviewRequestModal from "./ValuationReviewRequestModal";
import { RO_DISPUTE_INITIATED } from "../Constants";
import { confirmAlert } from "react-confirm-alert";
import { googleMapCustomMarker } from "components/Common/Helpers";
import { PopoverOnClick } from "components/PopoverOnHover/PopoverOnHover";
import MarketPlaceUrls from "components/MarketPlaceUrls/MarketPlaceUrls";
import DragAndDropOrUpload from "../File-Upload/DragAndDropOrUpload";
import AutoLoginDecrypt from "components/Common/AutoLoginDecrypt";
const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;
const userData = loggedInUser();

const DisputeOrderButton = ({ propRowData, propButtonOptions, google }) => {
    let [isOpenModal, setIsOpenModal] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [isProcess, setProcess] = useState(false);
    let [tabs, setTabs] = useState(1);
    let [defaultTableData, setDefaultTableData] = useState(null);
    let [compsData, setCompsData] = useState([]);
    let [isLoadMap, setIsLoadMap] = useState(false);
    let [google_prop, setGoogleProps] = useState(null);
    let [mapData, setMapData] = useState({ activeMarker: {}, selectedPlace: {}, showingInfoWindow: false });
    let [mapStyles] = useState({ width: '100%', height: '350px' });
    let [defaultMarkers, setDefaultMarkers] = useState([]);
    let [showCompMap, setShowCompMap] = useState(false);
    let [isOpenAcceptModal, setOpenAcceptModal] = useState(false);
    let [customErrors, setCustomErrors] = useState({ agreeError: false });
    let [selectedRow, setSelectedRow] = useState([]);
    let [isOpenDismissModel, setOpenDismissModal] = useState(false);
    let [selectedReasons, setSelectedReasons] = useState([]);
    let [disableContinue, setDisableContinue] = useState(true);
    let [isOpenDisputeDismissModel, setOpenDisputeDismissModel] = useState(false);
    let [fields, setFields] = useState({});
    let [isDisputeModal, setDisputeModal] = useState(false);
    let [orderData, setOrderData] = useState({});
    let [otherData, setOtherData] = useState({});
    let [tabIndex, setTabIndex] = useState(0);
    const [selectedPlansFiles, setSelectedPlansFiles] = useState([]);
    const [selectedAppraisalFiles, setSelectedAppraisalFiles] = useState([]);
    const [selectedValDisputeFiles, setSelectedValDisputeFiles] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        if (propRowData) {
            setGoogleProps(google);
        }
        //eslint-disable-next-line
    }, [propRowData]);

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleActionClick = async (e, row) => {
        e.preventDefault();
        await getSubmittedComps({ order_id: propRowData.order_id });
        setIsOpenModal(true);
    };

    let handleToggleModal = (state) => {
        setIsOpenModal(!state);
        setUpdate(moment());
    };

    let getSubmittedComps = async (params) => {
        try {
            setProcess(true);
            let result = await GetData("orders/get-submitted-comps", params);
            let responseJson = result.data;
            if (responseJson && result.success) {
                let submittedTabText = responseJson.submittedTabText;
                let subjectProperty = responseJson.subjectProperty;
                let finalSearchCompTypes = responseJson.finalSearchCompTypes;
                let arvComps = responseJson.arvComps;
                let maxDistance = responseJson.maxDistance;
                let totalComps = responseJson.totalComps;
                let selectedMaxDistance = responseJson.selectedMaxDistance;
                let selectedTotalComps = responseJson.selectedTotalComps;
                let apexPropTypes = responseJson.apexPropTypes;
                let isROVSubmitted = responseJson.isROVSubmitted;

                let analysisTrackingData = responseJson.analysisTrackingData;
                let analysisData = {
                    market_commentary: analysisTrackingData.market_commentary ? analysisTrackingData.market_commentary : '-',
                    subject_property_commentary: analysisTrackingData.subject_property_commentary ? analysisTrackingData.subject_property_commentary : '-',
                    budget_commentary: analysisTrackingData.budget_commentary ? analysisTrackingData.budget_commentary : '-',
                    valuation_commentary: analysisTrackingData.valuation_commentary ? analysisTrackingData.valuation_commentary : '-',
                }

                let arvCompsFilteredData = await prepareCompsArray({ comps: arvComps, subjectProperty, maxDistance });
                let arvCompsFiltered = arvCompsFilteredData.filteredComps;
                let arvMarkers = arvCompsFilteredData.markers;

                let asIsComps = responseJson.asIsComps;
                let asIsCompsFilteredData = await prepareCompsArray({ comps: asIsComps, subjectProperty, maxDistance });
                let asIsCompsFiltered = asIsCompsFilteredData.filteredComps;
                let asIsMarkers = asIsCompsFilteredData.markers;

                let bothComps = [...asIsCompsFiltered, ...arvCompsFiltered];
                prepareSubmitDisable(bothComps);

                // Set Default tab and data
                let [defaultTabData, defaultMarkerList] = [[], []];

                if (asIsCompsFiltered.length > 0) {
                    defaultTabData = asIsCompsFiltered;
                    defaultMarkerList = asIsMarkers;
                    setTabIndex(0);
                } else if (arvCompsFiltered.length > 0) {
                    defaultTabData = arvCompsFiltered;
                    defaultMarkerList = arvMarkers;
                    setTabIndex(1);
                }

                let thresholdDistance = defaultTabData.length ? defaultTabData[1].threshold_distance : 0;
                setDefaultTableData(defaultTabData);
                setDefaultMarkers(defaultMarkerList);

                let compsData = {
                    arvComps: arvCompsFiltered,
                    asIsComps: asIsCompsFiltered,
                    analysisData,
                    thresholdDistance,
                    submittedTabText,
                    finalSearchCompTypes,
                    arvMarkers,
                    asIsMarkers,
                    maxDistance,
                    totalComps,
                    selectedMaxDistance,
                    selectedTotalComps,
                    apexPropTypes,
                    asIsCompsArray: asIsComps,
                    arvCompsArray: arvComps,
                    isROVSubmitted
                }
                setCompsData(compsData);
                setIsLoadMap(true);
            }
            setProcess(false);
        } catch (error) {
            setProcess(false);
            await sendExceptionEmail(error, 'DisputeOrderButton', 'getSubmittedComps', {});
            toast["error"]("Something went wrong.");
        };
    }

    /**
     ** description: Handle Admin role(main tab) tab navigation
    */
    let toggleNavs = (e, state, index) => {
        e.preventDefault();
        setTabs(index);

        if (index === 1) {
            setDefaultTableData(compsData.asIsComps);
            setDefaultMarkers(compsData.asIsMarkers);
        } else {
            setDefaultTableData(compsData.arvComps);
            setDefaultMarkers(compsData.arvMarkers);
        }
        setIsLoadMap(true);
    };

    let handleFlags = (row) => {
        return (
            <>
                {row.golf === '1' ? (
                    <img src={require("../../../../assets/img/flags/golf.png").default} alt="Golf" width={20} />
                ) : (null)}

                {row.waterfront === '1' ? (
                    <img src={require("../../../../assets/img/flags/water.png").default} alt="Water" width={20} />
                ) : (null)}

                {row.pool === '1' ? (
                    <img src={require("../../../../assets/img/flags/pool.png").default} alt="pool" width={20} />
                ) : (null)}
            </>
        )
    }

    let handleAccept = (row) => {
        setSelectedRow(row);
        setOpenAcceptModal(true);
    }

    let handleDismiss = (row) => {
        setSelectedRow(row);
        setOpenDismissModal(true);
        setFields({});
        setSelectedReasons([]);
    }

    let acceptComp = (event, values) => {
        const agreeComp = event.target['agree_comp'].checked;
        setCustomErrors({ agreeError: !agreeComp });

        if (!agreeComp) {
            return false;
        }
        let valid = '0';
        if (selectedRow.already_in_comp_arrays === '1' || selectedRow.already_in_custom_report === '1' || selectedRow.already_in_db_yn === '1') {
            valid = '1';
        }
        values.valid = valid;
        values.type = 'accept';
        saveAcceptedComp(values);
        setOpenAcceptModal(false);
    }

    let dismissDispute = async (event, values) => {
        try {
            setProcess(true);
            values.order_id = propRowData.order_id;
            let result = await PostData("orders/dispute-order", values);
            let responseJson = result.data;
            if (responseJson && result.success) {
                toast["success"](result.message);
                setIsOpenModal(false);
                setOpenDisputeDismissModel(false)
                eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
            }
            setProcess(false);
        } catch (error) {
            setProcess(false);
            await sendExceptionEmail(error, 'DisputeOrderButton', 'dismissDispute', {});
            toast["error"]("Something went wrong.");
        };
    }

    let dismissComp = (event, values) => {
        let reason = null;
        if (selectedRow.already_in_custom_report === '1') {
            const agreeDismissComp = event.target['agree_dismiss_comp'].checked;
            setCustomErrors({ agreeError: !agreeDismissComp });

            if (!agreeDismissComp) {
                return false;
            }
            reason = `I have already fully considered this comparable, and therefore I am dismissing the request to look at this comp again`;
        } else {
            const agreeDismissCompRadio = event.target['agree_dismiss_comp_radio'][0].checked;
            const agreeDismissCompRadioWithOptions = event.target['agree_dismiss_comp_radio'][1].checked;
            if (!agreeDismissCompRadio && !agreeDismissCompRadioWithOptions) {
                setCustomErrors({ dismissError: true });
                return false;
            }
            if (agreeDismissCompRadioWithOptions && !selectedReasons.length) {
                setCustomErrors({ dismissOptionsError: true });
                return false;
            }
            if (agreeDismissCompRadio) {
                reason = `I have already fully considered this comparable, and therefore I am dismissing the request to look at this comp again`;
            }
            if (agreeDismissCompRadioWithOptions) {
                reason = selectedReasons.length && selectedReasons.join(', ');
            }
        }
        values.reason = reason;
        values.valid = '0';
        values.type = 'dismiss';
        saveAcceptedComp(values);
        setOpenDismissModal(false)
    }

    let saveAcceptedComp = async (params) => {
        try {
            setProcess(true);
            params.dispute_comp_id = selectedRow.id;
            params.property_id = selectedRow.report_order.property_id;
            params.company_id = selectedRow.report_order.company_id;
            params.order_id = selectedRow.report_order.id;
            let result = await PostData("orders/save-accept-comp", params);
            let responseJson = result.data;
            if (responseJson && result.success) {
                toast["success"](result.message);

                const updatedArray = addValuesToObjectById(selectedRow.id, { disable: true, dismiss: params.type === 'dismiss' ? true : false, accept: params.type === 'accept' ? true : false });
                setDefaultTableData(updatedArray);
                if (tabs === 1) {
                    compsData.asIsComps = updatedArray;
                    setCompsData(compsData);
                } else if (tabs === 2) {
                    compsData.arvComps = updatedArray;
                    setCompsData(compsData);
                }

                let bothComps = [...compsData.asIsComps, ...compsData.arvComps];
                prepareSubmitDisable(bothComps);
            }
            setProcess(false);
        } catch (error) {
            setProcess(false);
            await sendExceptionEmail(error, 'DisputeOrderButton', 'saveAcceptedComp', {});
            toast["error"]("Something went wrong.");
        };
    }

    let handleUndoComp = async (row) => {
        try {
            setProcess(true);
            let result = await PostData("orders/undo-dispute-comp", { dispute_comp_id: row.id });
            let responseJson = result.data;
            if (responseJson && result.success) {
                toast["success"](result.message);
                const updatedArray = addValuesToObjectById(row.id, { disable: false });
                setDefaultTableData(updatedArray);

                if (tabs === 1) {
                    compsData.asIsComps = updatedArray;
                    setCompsData(compsData);
                } else if (tabs === 2) {
                    compsData.arvComps = updatedArray;
                    setCompsData(compsData);
                }
                let bothComps = [...compsData.asIsComps, ...compsData.arvComps];
                prepareSubmitDisable(bothComps);
            }
            setProcess(false);
        } catch (error) {
            setProcess(false);
            await sendExceptionEmail(error, 'DisputeOrderButton', 'handleUndoComp', {});
            toast["error"]("Something went wrong.");
        };
    }

    let handleEditPopup = async (row) => {
        let orderData = {
            id: propRowData.order_id,
            property_id: propRowData.property_id,
            company_id: propRowData.company_id
        }
        setOrderData(orderData);

        // Prepare other data for manage add comprables modal
        let otherData = {};
        if (row.comp_type === 'asIs') {
            otherData.currentStep = 2;
            otherData.property_value_checkbox_1 = '1';
            otherData.asIsComps = compsData.asIsCompsArray;
        } else if (row.comp_type === 'arv') {
            otherData.currentStep = 3;
            otherData.property_value_checkbox_2 = '2';
            otherData.arvComps = compsData.arvCompsArray;
        }
        setOtherData(otherData);
        setDisputeModal(true);
    }

    let handleEditCompModal = async () => {
        let orderData = {
            id: propRowData.order_id,
            property_id: propRowData.property_id,
            company_id: propRowData.company_id
        }
        setOrderData(orderData);

        let otherData = {};
        otherData.currentStep = 1;
        otherData.maxWidth = '50%';

        setOtherData(otherData);
        setDisputeModal(true);
    }

    let handleAgreeCheckbox = (event) => {
        if (event.target.checked) {
            if (event.target.name === "agree_dismiss_comp_radio") {
                if (parseInt(event.target.value) === 1) {
                    setSelectedReasons([]);
                }
                setCustomErrors({ dismissError: false });
            } else {
                setCustomErrors({ agreeError: false });
            }
        }

        let tempFields = fields;
        tempFields[event.target.name] = event.target.value
        setFields(tempFields);
    }

    let handleOtherReasonSelection = (event) => {
        const value = event.target.value;
        setSelectedReasons((prevSelectedReasons) => {
            if (event.target.checked) {
                fields["agree_dismiss_comp_radio"] = 2;
                setFields(fields);
                setCustomErrors({ dismissOptionsError: false });
                return [...prevSelectedReasons, value];
            } else {
                return prevSelectedReasons.filter((reason) => reason !== value);
            }
        });
    }

    let handleAcceptCompModal = (state) => {
        setOpenAcceptModal(!state);
        setUpdate(moment());
    };

    let addValuesToObjectById = (id, newValues) => {
        const foundObjectIndex = defaultTableData.findIndex(obj => obj.id === id);
        if (foundObjectIndex !== -1) {
            defaultTableData[foundObjectIndex] = {
                ...defaultTableData[foundObjectIndex],
                ...newValues
            };
            return defaultTableData;
        } else {
            return defaultTableData;
        }
    }

    let countDisabledElements = (array, key) => {
        return array.reduce((count, element) => {
            if (element[`${key}`]) {
                count++;
            }
            return count;
        }, 0);
    }

    let prepareSubmitDisable = (allComps) => {
        let filterAllComps = allComps.filter(component => component.srNo !== 'S');
        let totalComps = filterAllComps.length;
        let totalReviewedComps = countDisabledElements(filterAllComps, "disable");

        if (totalComps === totalReviewedComps) {
            setDisableContinue(false);
        } else {
            setDisableContinue(true);
        }
    }

    let handleActionButtons = (row) => {
        if (!row || row.srNo === 'S') {
            return null; // or return some default value if needed
        }

        let compResponse = row.report_order_client_dispute_comp_responses.length > 0 ? row.report_order_client_dispute_comp_responses[0] : {};
        let tooltipPrefix = '';
        let buttonColor = '';
        if (parseInt(compResponse.valid) === 1 || row.accept || (parseInt(compResponse.valid) === 0 && parseInt(compResponse.dismiss_comp) !== 1)) {
            tooltipPrefix = "Accepted - ";
            buttonColor = "success";
        } else if ((parseInt(compResponse.valid) === 0 && parseInt(compResponse.dismiss_comp) === 1) || row.dismiss) {
            tooltipPrefix = "Dismissed - ";
            buttonColor = "danger";
        }

        const acceptButton = (
            <RenderActionButton
                propButtonTitle="Accept"
                propButtonOptions={{ faClass: "fa fa-check", color: "accept", outline: 'false' }}
                propOnClickEvent={() => handleAccept(row)}
            />
        );

        const dismissButton = (
            <RenderActionButton
                propButtonTitle="Dismiss Comp"
                propButtonOptions={{ faClass: "fa fa-ban", color: "danger", outline: 'false' }}
                propOnClickEvent={() => handleDismiss(row)}
            />
        );

        const editButton = (
            <RenderActionButton
                propButtonTitle="Edit"
                propButtonOptions={{ faClass: "fa fa-pencil-alt", color: "primary", outline: 'false' }}
                propOnClickEvent={() => handleEditPopup(row)}
            />
        );

        const undoButton = (
            <RenderActionButton
                propButtonTitle={`${tooltipPrefix} Undo`}
                propButtonOptions={{ faClass: "fa fa-undo", color: buttonColor, outline: false }}
                propOnClickEvent={() => handleUndoComp(row)}
            />
        );

        const isEditAllowed = (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) === true || checkRolesWithLevel(['REVIEWER'], ">=", 2) || userData.is_client === true);
        const isAdminAllowed = userData.is_client === false;

        return (
            <>
                {row.disable ? (
                    <>
                        {isAdminAllowed && undoButton}
                        {isEditAllowed && editButton}
                    </>
                ) : (
                    <>
                        {isAdminAllowed && acceptButton}
                        {isAdminAllowed && dismissButton}
                        {isEditAllowed && editButton}
                    </>
                )}
            </>
        );
    };


    let prepareCompsArray = async (params) => {
        let filteredComps = [];
        let markers = [];
        let index = 1;
        let comps = params.comps;
        if (comps.length) {
            for (const comp of comps) {
                let tempMarker = [];
                let tempArvComps = { ...comp };
                let propertyAddress = { address: comp.address, city: comp.city, state: comp.state, zip: comp.zip };
                let distance = comp.distance ? parseFloat(comp.distance) : 0;

                let included = null;
                let color = null;
                if (comp.already_in_db_yn === '1' || comp.already_in_comp_arrays === '1') {
                    included = 'Excluded';
                    color = 'FFFF00';
                } else if (comp.already_in_custom_report === '1') {
                    included = 'Selected';
                    color = '137ed9';
                } else {
                    included = 'New';
                    color = 'FFA500';
                }

                let disputeCompResponses = comp.report_order_client_dispute_comp_responses;
                let valid = null;
                let dismissComp = null;
                if (disputeCompResponses.length) {
                    valid = disputeCompResponses[0].valid;
                    dismissComp = disputeCompResponses[0].dismiss_comp;
                }
                let conditionData = getConditionRatingColor(comp.condition_rating);
                let lotSize = convertLotSize(comp.lot_sqft);
                tempArvComps.srNo = index;
                tempArvComps.address = propertyAddress;
                tempArvComps.above_grade_sqft = comp.above_grade_sqft ? number_format(comp.above_grade_sqft, 0) : 0;
                tempArvComps.below_grade_sqft = comp.below_grade_sqft ? number_format(comp.below_grade_sqft, 0) : 0;
                tempArvComps.sale_date = comp.sale_date ? formatDate('', comp.sale_date, "MMM D") : null;
                tempArvComps.close_price = comp.close_price ? priceNumber(comp.close_price, 0) : "-";
                tempArvComps.stories = comp.stories ? parseFloat(comp.stories) : 0;
                tempArvComps.distance = distance;
                tempArvComps.latitude = comp.latitude ? comp.latitude : null;
                tempArvComps.longitude = comp.longitude ? comp.longitude : null;
                tempArvComps.included = included;
                tempArvComps.disable = disputeCompResponses.length ? true : false;
                tempArvComps.baths = number_format(comp.baths, 2);
                tempArvComps.lot_sqft = number_format(lotSize, 2);
                tempArvComps.dismiss = (valid === '0' && dismissComp === '1') ? true : false;
                tempArvComps.conditionRating = conditionData.condition;
                tempArvComps.conditionRatingColor = conditionData.color;
                filteredComps.push(tempArvComps);

                // Prepare Markers Data
                tempMarker.id = comp.id;
                tempMarker.name = propertyAddress;
                tempMarker.position = { lat: comp.latitude, lng: comp.longitude };
                tempMarker.distance = distance;
                tempMarker.label = `${index}`;
                tempMarker.street_view = `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${comp.latitude},${comp.longitude}&fov=90&pitch=10&key=${process.env.REACT_APP_GOOGLE_ADDRESS_KEY}`;
                tempMarker.close_price = comp.close_price ? priceNumber(comp.close_price, 0) : "";
                tempMarker.above_grade_sqft = comp.above_grade_sqft ? number_format(comp.above_grade_sqft, 0) : 0;




                let basement_sqft = 0;
                if (comp.below_grade_sqft && comp.below_grade_sqft > 0) {
                    basement_sqft = comp.below_grade_sqft;
                }
                let total = comp.above_grade_sqft + basement_sqft;
                let pricePerSqFt = (comp.above_grade_sqft === 0) ? 'N/A' : priceNumber(comp.close_price / total);
                tempMarker.pricePerSqFt = pricePerSqFt;
                tempMarker.color = color;
                tempMarker.conditionRating = conditionData.condition;
                tempMarker.conditionRatingColor = conditionData.color;
                tempMarker.conditionRatingUrl = conditionData.imageSrc;
                tempMarker.included = included;
                markers.push(tempMarker);
                index++;
            }
        }

        // Prepare subject property
        let subjectProperty = params.subjectProperty;
        let maxDistance = params.maxDistance;
        if (subjectProperty) {
            let propertyAddress = { address: subjectProperty.address, city: subjectProperty.city, state: subjectProperty.state, zip: subjectProperty.postal_code };
            let basement_sqft = 0;
            if (subjectProperty.finished_basement && subjectProperty.finished_basement > 0) {
                basement_sqft = subjectProperty.finished_basement;
            }
            let beds_val = number_format(subjectProperty.bedrooms_total);
            let baths_val = number_format(subjectProperty.bathrooms_total, 2);
            let stories_total = subjectProperty.stories_total ? parseFloat(subjectProperty.stories_total) : 0;
            let garage = subjectProperty.garage;
            let above_grade_sqft = subjectProperty.living_area_square_feet ? number_format(subjectProperty.living_area_square_feet, 0) : 0;
            let lotSize = convertLotSize(subjectProperty.lot_size_square_feet);

            let zoom_level = 18;
            if (maxDistance >= 2) {
                zoom_level = 12;
            } else if (maxDistance >= 1.5 && maxDistance <= 2) {
                zoom_level = 13;
            } else if (maxDistance >= 1 && maxDistance <= 1.5) {
                zoom_level = 14;
            } else if (maxDistance >= 0.5 && maxDistance <= 1) {
                zoom_level = 15;
            } else if (maxDistance >= 0 && maxDistance <= 0.5) {
                zoom_level = 16;
            }

            let tempSubComp = {
                id: subjectProperty.id,
                srNo: 'S',
                address: propertyAddress,
                distance: null,
                included: null,
                property_type_normalized: capitalizeFirstLetter(subjectProperty.property_type_normalized),
                above_grade_sqft,
                below_grade_sqft: number_format(basement_sqft),
                beds: beds_val,
                baths: baths_val,
                year: subjectProperty.year_built,
                lot_sqft: number_format(lotSize, 2),
                stories: stories_total,
                garage: garage,
                close_price: null,
                sale_date: null,
                listing_url: null,
                mls_number: null,
                disable: false
            }
            if (filteredComps.length) {
                filteredComps.unshift(tempSubComp);
            }

            // Prepare Markers Data
            let sbjMarker = {
                id: -1,
                name: subjectProperty.address,
                position: { lat: subjectProperty.latitude, lng: subjectProperty.longitude },
                street_view: `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${subjectProperty.latitude},${subjectProperty.longitude}&fov=90&pitch=10&key=${process.env.REACT_APP_GOOGLE_ADDRESS_KEY}`,
                label: 'S',
                color: 'DD4B3E',
                above_grade_sqft,
                zoom_level
            }
            markers.unshift(sbjMarker);
        }
        return { filteredComps, markers }
    }

    let handleContinue = async () => {
        let bothComps = [...compsData.asIsComps, ...compsData.arvComps];
        let filterAllComps = bothComps.filter(component => component.srNo !== 'S');
        let totalComps = filterAllComps.length;
        let totalReviewedComps = countDisabledElements(filterAllComps, "dismiss");

        if (totalComps === totalReviewedComps) {
            setOpenDisputeDismissModel(true);
        } else {
            if (compsData.isROVSubmitted === 1) {
                confirmAlert({
                    title: 'Are you sure you want to submit the ROV again?',
                    closeOnClickOutside: false,
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => submitROVProcess(filterAllComps),
                            className: "btn btn-primary btn-sm"
                        }, {
                            label: 'No',
                            onClick: () => { },
                            className: "btn btn-secondary btn-sm"
                        }
                    ]
                });
            } else {
                submitROVProcess(filterAllComps)
            }
        }
    }

    let submitROVProcess = async (filterAllComps) => {
        const compIds = filterAllComps.map(obj => obj.id);
        await sendAcceptDisputeEmail();
        await updateCompsARV({ ...propRowData, compIds });
        setIsOpenModal(false);
    }

    let sendAcceptDisputeEmail = async () => {
        try {
            setProcess(true);
            await PostData("orders/dispute-order-agree-email", propRowData);
            setProcess(false);
        } catch (error) {
            setProcess(false);
            await sendExceptionEmail(error, 'DisputeOrderButton', 'sendAcceptDisputeEmail', {});
            toast["error"]("Something went wrong.");
        };
    }

    let updateCompsARV = async (params) => {
        try {
            setProcess(true);
            let result = await PostData("orders/dispute-comps-update", params);
            let responseJson = result.data;
            if (responseJson && result.success) {
                toast["success"](result.message);
                compsData.isROVSubmitted = 1;
                setCompsData(compsData);
                await disputeCompSummary(responseJson.updateRecord);
            }
            setProcess(false);
        } catch (error) {
            setProcess(false);
            await sendExceptionEmail(error, 'DisputeOrderButton', 'updateCompsARV', {});
            toast["error"]("Something went wrong.");
        };
    }

    let disputeCompSummary = async (isRecordUpdated) => {
        try {
            if (isRecordUpdated) {
                setProcess(true);
                await axios({
                    url: process.env.REACT_APP_PHP_ENDPOINT + "valuation/property_calculations/recalculateBtn/" + propRowData.property_id,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                setProcess(false);
            }
            let valDetailPage = `${process.env.REACT_APP_PHP_ENDPOINT}valuation/deals/details/${propRowData.property_id}`;
            AutoLogin({ user_id: userData.user_id, url: valDetailPage }, (response) => { });
        } catch (error) {
            setProcess(false);
            await sendExceptionEmail(error, 'DisputeOrderButton', 'disputeCompSummary', {});
            toast["error"]("Something went wrong.");
        }
    }



    let onMarkerClick = (props, marker) => {
        setMapData({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    const markerLoadHandler = (label, color = '22caab', conditionRatingUrl, conditionRating, included) => {
        let [sizeX, sizeY] = [22, 22];
        if (label === 'S') {
            conditionRatingUrl = googleMapCustomMarker(label, color)
            sizeX = 35;
            sizeY = 50;
        } else if (included === "New") {
            let conditionResult = getConditionRatingForNewComp(conditionRating);
            conditionRatingUrl = conditionResult.imageSrc;
        }
        var icon = {
            url: `${conditionRatingUrl}`, // url
            scaledSize: new google_prop.maps.Size(sizeX, sizeY), // scaled size
            origin: new google_prop.maps.Point(0, 0), // origin
            anchor: new google_prop.maps.Point(0, 0)
        };
        return icon;
    };

    //  Map box close
    let onClose = () => {
        if (mapData.showingInfoWindow) {
            setMapData({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    const onToggleMapButton = async (checked) => {
        setShowCompMap(checked);
    };

    let handleReason = (row) => {
        const disputeId = `reason_${row.id}`;
        return (
            <div>
                <span id={disputeId}>{truncate(row.client_comments, 25)}</span>
                <UncontrolledTooltip
                    delay={0}
                    placement="auto"
                    target={disputeId}
                >
                    {row.client_comments}
                </UncontrolledTooltip>
            </div>

        );
    };

    let getReviewRequestModal = async (data) => {
        setDisputeModal(data.state);

        if (data.refreshData) {
            await getSubmittedComps({ order_id: propRowData.order_id });
        }
    }

    let handleConditionRatingColor = (row) => {
        let backgroundColor = row.conditionRatingColor;
        let color = "#ffffff";
        if (backgroundColor === "#50C9D6" || backgroundColor === "#74D170" || backgroundColor === "#E6E85E") {
            color = "#000000";
        }
        return (
            <>
                {row.conditionRating ?
                    <div className="condition-color" style={{ backgroundColor: backgroundColor, color: color, borderColor: backgroundColor }}>{ConditionRatingsFormate(row.condition_rating)}</div > : ""
                }
            </>
        );
    }


    const handleAddress = (row) => {
        const address = row.address.address
        const city = row.address.city
        const state = row.address.state
        const postal_code = row.address.zip

        const propertyAddress = `${row.address.address}, ${row.address.city}, ${row.address.state} ${row.address.zip}`;
        return <PopoverOnClick
            placement="right"
            type="legacy"
            title="Quick Action"
            className="bg-light"
            description={<MarketPlaceUrls address={{ address, city, state, postal_code }} googleSearch={true} />}
            id={"-row-number-" + row.id}
            key={"-row-number-" + row.id}
        > <span className="custom-link">{propertyAddress}</span></PopoverOnClick>
    }

    let columns = [
        { title: "", width: "0%", dataField: "id", isKey: true, hidden: true },
        { title: "", width: "2%", dataField: "srNo", align: "center" },
        { title: "Address", width: "10%", dataFormat: (cell, row) => handleAddress(row), align: "left" },
        { title: "Dist", width: "2%", dataField: "distance", align: "center" },
        { title: "Incl", width: "5%", dataField: "included", align: "center" },
        { title: `Type`, width: "5%", dataField: "property_type_normalized", align: "center" },
        { title: `Above`, width: "4%", dataField: "above_grade_sqft", align: "center" },
        { title: `Below`, width: "3%", dataField: "below_grade_sqft", align: "center" },
        { title: `BD`, width: "2%", dataField: "beds", align: "center" },
        { title: `BT`, width: "2%", dataField: "baths", align: "center" },
        { title: `Years`, width: "3%", dataField: "year", align: "center" },
        { title: `Lot`, width: "3%", dataField: "lot_sqft", align: "center" },
        { title: `Stories`, width: "3%", dataField: "stories", align: "center" },
        { title: `Flags`, width: "5%", dataFormat: (cell, row) => handleFlags(row), align: "center" },
        { title: `Garage`, width: "4%", dataField: "garage", align: "center" },
        { title: `C`, width: "3%", dataFormat: (cell, row) => handleConditionRatingColor(row), align: "center" },
        { title: `Sale Price`, width: "7%", dataField: "close_price", align: "center" },
        { title: `Sale Date`, width: "5%", dataField: "sale_date", align: "center" },
        { title: `MLS URL`, width: "8%", dataField: "listing_url", align: "center" },
        { title: `MLS Number`, width: "5%", dataField: "mls_number", align: "center" },
        { title: `Reason`, width: "12%", dataFormat: (cell, row) => handleReason(row), align: "center" },
        { title: ``, width: "8%", dataFormat: (cell, row) => handleActionButtons(row), align: "center" },
    ];

    const tableParams = {
        tableData: defaultTableData,
        columns,
    };


    let handleSelectedFile = (files, fileType) => {
        if (fileType === "plans") {
            data.plan_files = files?.selectedFiles;
            setSelectedPlansFiles(files?.selectedFiles);
        } else if (fileType === "appraisal") {
            data.appraisal_files = files?.selectedFiles;
            setSelectedAppraisalFiles(files?.selectedFiles);
        } else if (fileType === "val_dispute") {
            data.val_dispute_files = files?.selectedFiles;
            setSelectedValDisputeFiles(files?.selectedFiles);
        }
        setData(data);
    };

    let clearForm = () => {
        setSelectedPlansFiles([]);
        setSelectedAppraisalFiles([]);
        setSelectedValDisputeFiles([]);
        setData({});
    }

    let handleFileSave = async () => {
        try {
            let params = {
                report_id: propRowData.order_id,
                property_id: propRowData.property_id,
                user_id: userData.user_id
            };
            let requestDataForm = new FormData();
            if (data.plan_files && data.plan_files.length) {
                for (const file of data.plan_files) {
                    requestDataForm.append('plan_files', file);
                }
            }
            if (data.appraisal_files && data.appraisal_files.length) {
                for (const file of data.appraisal_files) {
                    requestDataForm.append('appraisal_files', file);
                }
            }
            if (data.val_dispute_files && data.val_dispute_files.length) {
                for (const file of data.val_dispute_files) {
                    requestDataForm.append('val_dispute_files', file);
                }
            }
            for (let key in params) {
                requestDataForm.append(key, params[key]);
            }
            if ((Object.keys(data).length > 0) && (selectedPlansFiles.length > 0 || selectedAppraisalFiles.length > 0 || selectedValDisputeFiles.length > 0)) {
                setProcess(true);
                const response = await PostData("orders/save_documents_uploads", requestDataForm)
                clearForm();
                toast["success"](response.message);
                setCustomErrors({ fileUploadError: false });
                setProcess(false);
            } else {
                setCustomErrors({ fileUploadError: true });
            }
        } catch (error) {
            await sendExceptionEmail(error, 'DisputeOrderButton', 'handleFileSave', {});
            toast["error"]("Something went wrong.");
            setProcess(false);
        }
    }


    return (
        <>
            <RenderActionButton propButtonTitle="Reconsideration Of Value" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {isOpenModal &&
                <Modal
                    className="modal-dialog-centered modal-xl dispute-order-revise"
                    isOpen={isOpenModal}
                    toggle={() => handleToggleModal(isOpenModal)}
                    style={{ maxWidth: "95%" }}
                    backdrop="static"
                >
                    <div className="modal-header modal-header-colored">
                        <h4 className="modal-title text-left w-100">
                            Comp List: <AutoLoginDecrypt
                                data={{
                                    label: propRowData.address,
                                    url: `${phpUrl}valuation/deals/details/${propRowData.property_id}`,
                                }}
                            />
                        </h4>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Spinner isShow={isProcess} />
                        <Row>
                            <div className="col-md-12 p-0">
                                <Card className="shadow">
                                    <Row className="m-1">
                                        <Col md={4}>
                                            <p className="h3 float-left w-100 text-light-blue">Commentary:</p>
                                            <Row className="row-even">
                                                <Col sm={3} className="label-column">
                                                    <strong>Market:</strong>
                                                </Col>
                                                <Col sm={9} className="text-center">
                                                    {compsData.analysisData.market_commentary}
                                                </Col>
                                            </Row>

                                            <Row className="row-odd">
                                                <Col sm={3} className="label-column">
                                                    <strong>Subject:</strong>
                                                </Col>
                                                <Col sm={9} className="text-center">
                                                    {compsData.analysisData.subject_property_commentary}
                                                </Col>
                                            </Row>

                                            <Row className="row-even">
                                                <Col sm={3} className="label-column">
                                                    <strong>Budget:</strong>
                                                </Col>
                                                <Col sm={9} className="text-center">
                                                    {compsData.analysisData.budget_commentary}
                                                </Col>
                                            </Row>

                                            <Row className="row-odd">
                                                <Col sm={3} className="label-column">
                                                    <strong>Valuation:</strong>
                                                </Col>
                                                <Col sm={9} className="text-center">
                                                    {compsData.analysisData.valuation_commentary}
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md={8}>
                                            <div className="float-right w-80">
                                                <p className="h3 float-left w-100 text-light-blue">Comp Selection Parameters:</p>
                                                <p className="w-100">{compsData.submittedTabText} {compsData.finalSearchCompTypes}</p>

                                                <Row className="row-even">
                                                    <Col sm={3} className="label-column">
                                                        <strong>Comps Selected:</strong>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <span className="text-light-blue font-weight-bold">{compsData.selectedTotalComps}</span> comps within <span className="text-light-blue font-weight-bold">{compsData.selectedMaxDistance}</span> miles
                                                    </Col>
                                                </Row>

                                                <Row className="row-odd">
                                                    <Col sm={3} className="label-column">
                                                        <strong>All Comps:</strong>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <span className="text-light-blue font-weight-bold">{compsData.totalComps}</span> comps within <span className="text-light-blue font-weight-bold">{compsData.maxDistance}</span> miles
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>

                                    {userData.is_client && <Row className="m-1 drop-zone-area">
                                        <Col className="col-md-3">
                                            <DragAndDropOrUpload label="Appraisal" accept="pdf" fileUploadCB={(files) => handleSelectedFile(files, 'appraisal')} maxFileSize={20} maxFiles={3} />
                                        </Col>
                                        <Col className="col-md-3">
                                            <DragAndDropOrUpload label="Architectural Plans" accept="pdf" fileUploadCB={(files) => handleSelectedFile(files, 'plans')} maxFileSize={20} maxFiles={3} />
                                        </Col>
                                        <Col className="col-md-3">
                                            <DragAndDropOrUpload label="Valuation Dispute" accept="pdf" fileUploadCB={(files) => handleSelectedFile(files, 'val_dispute')} maxFileSize={20} maxFiles={3} />
                                            <Col className="text-right">
                                                <Button size="sm" color="info" onClick={() => handleFileSave()}>Save files</Button>
                                            </Col>
                                        </Col>
                                        {customErrors.fileUploadError && <div className="invalid-feedback">Please upload at least one file.</div>}
                                    </Row>}

                                    {/* Comps List  */}
                                    <Row className="m-1">
                                        <Col md={4}>
                                            <p className="h3 float-left text-light-blue">Comps Submitted by Client for Consideration:</p>
                                        </Col>
                                        <Col md={4}>
                                            <p className="h3 float-right">Suggested Distance Threshold:
                                                <span className="text-light-blue font-weight-bold"> {compsData.thresholdDistance} miles</span>
                                            </p>
                                        </Col>
                                        <Col md={4} >
                                            {(compsData.asIsComps.length === 0 && compsData.arvComps.length === 0) &&
                                                <div className="float-right ml-2 pt-1">
                                                    <RenderActionButton
                                                        propButtonTitle="Edit"
                                                        propButtonOptions={{ faClass: "fa fa-pencil-alt", outline: 'false' }}
                                                        propOnClickEvent={() => handleEditCompModal()}
                                                    />
                                                </div>
                                            }
                                            <ToggleSwitch
                                                id="showMapToggle"
                                                className="float-right dispute-show-map-toggle"
                                                checked={showCompMap}
                                                onChange={(e) => onToggleMapButton(e)}
                                                optionLabels={['Show Map', 'Hide Map']}
                                            />
                                        </Col>

                                        <Col md={12}>
                                            {showCompMap &&
                                                <Col lg="12" xl="12">
                                                    <Row className="myMapDiv pt-2">
                                                        {isLoadMap ? (
                                                            <GMap
                                                                className="custom-map-controls"
                                                                contextMenu={true}
                                                                google={google_prop}
                                                                zoom={defaultMarkers?.[0]?.zoom_level}
                                                                style={mapStyles}
                                                                zoomControlOptions={{
                                                                    position: google_prop.maps.ControlPosition.BOTTOM_RIGHT
                                                                }}
                                                                initialCenter={
                                                                    { lat: defaultMarkers?.[0]?.position?.lat, lng: defaultMarkers?.[0]?.position?.lng }
                                                                }
                                                            >
                                                                {defaultMarkers?.map(({ id, name, position, label, close_price, pricePerSqFt, color, street_view, distance, above_grade_sqft, conditionRatingUrl, conditionRating, included }) => (

                                                                    <Marker
                                                                        onClick={onMarkerClick}
                                                                        key={id}
                                                                        title={name}
                                                                        position={position}
                                                                        close_price={close_price}
                                                                        pricePerSqFt={pricePerSqFt}
                                                                        icon={markerLoadHandler(label, color, conditionRatingUrl, conditionRating, included)}
                                                                        street_view={street_view}
                                                                        distance={distance}
                                                                        above_grade_sqft={above_grade_sqft}
                                                                        name={name} >
                                                                    </Marker>

                                                                ))}
                                                                <InfoWindow
                                                                    marker={mapData.activeMarker}
                                                                    visible={mapData.showingInfoWindow}
                                                                    onClose={onClose}>
                                                                    <div className="map-link">
                                                                        {mapData.selectedPlace &&
                                                                            <>
                                                                                <Col lg="12" xl="12">
                                                                                    <Row>
                                                                                        <a href={"https://www.google.com/maps/place/" + mapData.selectedPlace.name + "/@" + mapData.selectedPlace.position?.lat + "," + mapData.selectedPlace.position?.lng + ",20z"} target="_blank" rel="noreferrer">
                                                                                            <p className="font-weight-bold">{mapData.selectedPlace.title}</p>
                                                                                        </a>
                                                                                    </Row>
                                                                                    <Row className="d-block">
                                                                                        <Col md="6" className="float-left">
                                                                                            <img width={140} src={mapData.selectedPlace.street_view} alt="Google Street View" />
                                                                                        </Col>
                                                                                        <Col md="5" className="float-right">
                                                                                            {mapData.selectedPlace.distance && <p className="font-weight-bolder">{mapData.selectedPlace.distance} Miles</p>}
                                                                                            {mapData.selectedPlace.close_price && <p className="font-weight-bolder">{mapData.selectedPlace.close_price}</p>}
                                                                                            <p className="font-weight-bolder">{mapData.selectedPlace.above_grade_sqft} sqft</p>
                                                                                            <p className="font-weight-bolder">{(mapData.selectedPlace.pricePerSqFt && mapData.selectedPlace.pricePerSqFt !== 'N/A') ? `${mapData.selectedPlace.pricePerSqFt}/sqft` : ''}</p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </>
                                                                        }

                                                                    </div>
                                                                </InfoWindow>
                                                            </GMap>
                                                        ) : (
                                                            null
                                                        )}
                                                    </Row>
                                                </Col>
                                            }
                                            <div className="order-listing-table mt-4">
                                                <Tabs forceRenderTabPanel selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                                    <TabList>
                                                        <Tab disabled={compsData.asIsComps.length === 0}
                                                            onClick={(e) => {
                                                                if (compsData.asIsComps.length > 0) {
                                                                    toggleNavs(e, "tabs", 1);
                                                                }
                                                            }}> As Is Comps </Tab>
                                                        <Tab disabled={compsData.arvComps.length === 0}
                                                            onClick={(e) => {
                                                                if (compsData.arvComps.length > 0) {
                                                                    toggleNavs(e, "tabs", 2);
                                                                }
                                                            }} > ARV Comps </Tab>
                                                    </TabList>

                                                    <TabPanel>
                                                        <div className="table-responsive dispute-step1-table mt-3">
                                                            <RenderBootstrapTable tableParams={tableParams} pagination={false} />
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <div className="table-responsive dispute-step1-table mt-3">
                                                            <RenderBootstrapTable tableParams={tableParams} pagination={false} />
                                                        </div>
                                                    </TabPanel>
                                                </Tabs>
                                            </div>
                                        </Col>

                                        <Col md="12" className="text-right mt-2 mb--3">
                                            <FormGroup>
                                                <Button size="lg" color="primary" disabled={disableContinue} onClick={() => handleContinue()} >Continue</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Row>
                    </div>
                </Modal>
            }

            {/* Accept confirmation modal */}
            {isOpenAcceptModal &&
                <Modal
                    className="modal-dialog-centered modal-xl dispute-order-revise"
                    isOpen={isOpenAcceptModal}
                    toggle={() => handleAcceptCompModal(isOpenAcceptModal)}
                    style={{ maxWidth: "40%" }}
                >
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title text-center w-100">
                            Accept Comparable
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleAcceptCompModal(isOpenAcceptModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Spinner isShow={isProcess} />
                        <AvForm onValidSubmit={(event, values) => acceptComp(event, values)}>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label>Comment <span className="required">*</span></Label>
                                        <AvField maxLength="2000" type="textarea" cols="100" name="comment" id="comment" placeholder="Type here..." required />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="agree_comp"
                                            type="checkbox"
                                            required
                                            name="agree_comp"
                                            value={1}
                                            onChange={(e) => handleAgreeCheckbox(e)}
                                        />
                                        <label className="custom-control-label" htmlFor="agree_comp">
                                            I agree this comp is valid
                                        </label>
                                    </div>
                                    {customErrors.agreeError && <div className="invalid-feedback">This field is required</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="text-right mt-2 mb--3">
                                    <FormGroup>
                                        <Button size="sm" color="danger" outline onClick={() => handleAcceptCompModal(isOpenAcceptModal)}>
                                            Close
                                        </Button>
                                        <Button size="sm" color="primary">Submit</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm >
                    </div>
                </Modal>
            }

            {/* Dismiss the comp */}
            {isOpenDismissModel &&
                <Modal
                    className="modal-dialog-centered modal-xl dispute-order-revise"
                    isOpen={isOpenDismissModel}
                    toggle={() => setOpenDismissModal(false)}
                    style={{ maxWidth: "40%" }}
                >
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title text-center w-100">
                            Dismiss comparable
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setOpenDismissModal(false)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Spinner isShow={isProcess} />
                        <AvForm onValidSubmit={(event, values) => dismissComp(event, values)}>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label>Comment <span className="required">*</span></Label>
                                        <AvField maxLength="2000" type="textarea" cols="100" name="comment" id="comment" placeholder="Type here..." required />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {selectedRow.already_in_custom_report === '1' ? (
                                        <>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    id="agree_dismiss_comp"
                                                    type="checkbox"
                                                    required
                                                    name="agree_dismiss_comp"
                                                    value={1}
                                                    onChange={(e) => handleAgreeCheckbox(e)}
                                                />
                                                <label className="custom-control-label" htmlFor="agree_dismiss_comp">
                                                    I have already fully considered this comparable, and therefore I am dismissing the request to look at this comp again
                                                </label>
                                            </div>
                                            {customErrors.agreeError && <div className="invalid-feedback">This field is required</div>}
                                        </>
                                    ) : (
                                        <>
                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    id="agree_dismiss_comp_radio_1"
                                                    name="agree_dismiss_comp_radio"
                                                    type="radio"
                                                    value={1}
                                                    checked={parseInt(fields.agree_dismiss_comp_radio) === 1 ? true : false}
                                                    onChange={(e) => handleAgreeCheckbox(e)}
                                                />
                                                <label className="custom-control-label" htmlFor="agree_dismiss_comp_radio_1">
                                                    I have already fully considered this comparable, and therefore I am dismissing the request to look at this comp again
                                                </label>
                                            </div>

                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    id="agree_dismiss_comp_radio_2"
                                                    name="agree_dismiss_comp_radio"
                                                    type="radio"
                                                    value={2}
                                                    checked={parseInt(fields.agree_dismiss_comp_radio) === 2 ? true : false}
                                                    onChange={(e) => handleAgreeCheckbox(e)}
                                                />
                                                <label className="custom-control-label" htmlFor="agree_dismiss_comp_radio_2">
                                                    I do not believe this comparable is valid for consideration, for the following reasons:
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox other-reasons-checkbox-div">
                                                <div className="reasons-checkbox">
                                                    <div className="float-left w-100">
                                                        <input
                                                            className="custom-control-input"
                                                            id="other_reasons_1"
                                                            type="checkbox"
                                                            name="other_reasons[]"
                                                            value={"Too far"}
                                                            checked={selectedReasons.includes("Too far") ? true : false}
                                                            onChange={(e) => handleOtherReasonSelection(e)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="other_reasons_1">
                                                            Too far
                                                        </label>
                                                    </div>
                                                    <div className="float-left w-100">
                                                        <input
                                                            className="custom-control-input"
                                                            id="other_reasons_2"
                                                            type="checkbox"
                                                            name="other_reasons[]"
                                                            value={"Incomparable neighborhood"}
                                                            checked={selectedReasons.includes("Incomparable neighborhood") ? true : false}
                                                            onChange={(e) => handleOtherReasonSelection(e)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="other_reasons_2">
                                                            Incomparable neighborhood
                                                        </label>
                                                    </div>
                                                    <div className="float-left w-100">
                                                        <input
                                                            className="custom-control-input"
                                                            id="other_reasons_3"
                                                            type="checkbox"
                                                            name="other_reasons[]"
                                                            value={"Superior quality or condition"}
                                                            checked={selectedReasons.includes("Superior quality or condition") ? true : false}
                                                            onChange={(e) => handleOtherReasonSelection(e)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="other_reasons_3">
                                                            Superior quality or condition
                                                        </label>
                                                    </div>
                                                    <div className="float-left w-100">
                                                        <input
                                                            className="custom-control-input"
                                                            id="other_reasons_4"
                                                            type="checkbox"
                                                            name="other_reasons[]"
                                                            value={"Too large or small"}
                                                            checked={selectedReasons.includes("Too large or small") ? true : false}
                                                            onChange={(e) => handleOtherReasonSelection(e)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="other_reasons_4">
                                                            Too large or small
                                                        </label>
                                                    </div>
                                                    <div className="float-left w-100">
                                                        <input
                                                            className="custom-control-input"
                                                            id="other_reasons_5"
                                                            type="checkbox"
                                                            name="other_reasons[]"
                                                            value={"Incomparable age or vintage"}
                                                            checked={selectedReasons.includes("Incomparable age or vintage") ? true : false}
                                                            onChange={(e) => handleOtherReasonSelection(e)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="other_reasons_5">
                                                            Incomparable age or vintage
                                                        </label>
                                                    </div>
                                                    <div className="float-left w-100">
                                                        <input
                                                            className="custom-control-input"
                                                            id="other_reasons_6"
                                                            type="checkbox"
                                                            name="other_reasons[]"
                                                            value={"Other"}
                                                            checked={selectedReasons.includes("Other") ? true : false}
                                                            onChange={(e) => handleOtherReasonSelection(e)}
                                                        />
                                                        <label className="custom-control-label" htmlFor="other_reasons_6">
                                                            Other
                                                        </label>
                                                        {selectedReasons.includes("Other") &&
                                                            <AvGroup>
                                                                <Label>Comment <span className="required">*</span></Label>
                                                                <AvField maxLength="2000" type="textarea" cols="100" name="other_comment" id="other_comment" placeholder="Type here..." required />
                                                            </AvGroup>
                                                        }
                                                    </div>
                                                </div>
                                                {customErrors.dismissOptionsError && <div className="invalid-feedback">Please select at least one reason for dismiss comparable</div>}
                                            </div>
                                            {customErrors.dismissError && <div className="invalid-feedback">Please select at least one reason for dismiss comparable</div>}
                                        </>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="text-right mt-2 mb--3">
                                    <FormGroup>
                                        <Button size="sm" color="danger" outline onClick={() => setOpenDismissModal(false)}>
                                            Close
                                        </Button>
                                        <Button size="sm" color="primary">Submit</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm >
                    </div>
                </Modal>
            }

            {/* Dismiss dispute */}
            {isOpenDisputeDismissModel &&
                <Modal
                    className="modal-dialog-centered modal-xl dispute-order-revise"
                    isOpen={isOpenDisputeDismissModel}
                    toggle={() => setOpenDisputeDismissModel(false)}
                    style={{ maxWidth: "40%" }}
                >
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title text-center w-100">
                            Dismiss Comparable
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setOpenDisputeDismissModel(false)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Spinner isShow={isProcess} />
                        <AvForm onValidSubmit={(event, values) => dismissDispute(event, values)}>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label>Comment <span className="required">*</span></Label>
                                        <AvField maxLength="2000" type="textarea" cols="100" name="comment" id="comment" placeholder="Type here..." required />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="text-right mt-2 mb--3">
                                    <FormGroup>
                                        <Button size="sm" color="danger" outline onClick={() => setOpenDisputeDismissModel(false)}>
                                            Close
                                        </Button>
                                        <Button size="sm" color="primary">Submit</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm >
                    </div>
                </Modal>
            }

            {isDisputeModal && <ValuationReviewRequestModal isDisputeModal={isDisputeModal} propReviewRequestModal={getReviewRequestModal} orderData={orderData} reopenStatus={RO_DISPUTE_INITIATED} otherData={otherData} />}
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(DisputeOrderButton);
