import React, { useState, useEffect } from "react";
import { Button, Row, Col, CardFooter, CardBody, UncontrolledTooltip } from "reactstrap";
import "./style.scss";
import { loggedInUser } from "components/Common/Helpers.js"
import Spinner from "components/Common/Spinner.js";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { AvForm } from "availity-reactstrap-validation";
import { PostData } from "services/PostData";
import { toast } from 'react-toastify';
import * as moment from "moment";
import { googleMapCustomMarker } from "components/Common/Helpers";
const mapStyles = { width: '95%', height: '525px' };
const userData = loggedInUser();
let markerColors = ['22caab', '575757', 'A63322', 'F4AB2C', 'E6E85E', '74D170', '50C9D6', '3D3DDF', 'AB40D7'];

const LandUses = ({ propData, onHandleLandUsesResponse }) => {

    const [isProcess, setProcess] = useState(false);
    let [landUses, setLandUses] = useState([]);
    let [landUsesIds, setLandUsesIds] = useState([]);
    let [subjectProperty, setSubjectProperty] = useState({});
    let [update, setUpdate] = useState(moment());
    let [customError, setCustomError] = useState("");
    let [cancelLandUses, setCancelLandUses] = useState([]);
    let [subjectPropertyComp, setSubjectPropertyComp] = useState([]);
    let [myCompMarker, setMyCompMarker] = useState([]);
    let [isToggled, setIsToggled] = useState(false);


    useEffect(() => {

        setLandUses(propData.land_uses);
        setSubjectProperty(propData.subject_property);
        let sbjMarker = [];

        if (propData.subject_property_comps) {
            let myCompMarkers = [];
            myCompMarkers.push(sbjMarker);
            let compDistance = [];
            propData.subject_property_comps.forEach(element => {
                let tempMarker = [];
                // Prepare Markers Data
                let price = "$" + (element.close_price / 1000).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + "k";
                tempMarker.id = element.id;
                tempMarker.name = element.address;
                tempMarker.position = { lat: element.latitude, lng: element.longitude };
                tempMarker.is_comp_entry_for_property = 0;
                tempMarker.label = `${price}`;
                tempMarker.condition_rating = element.condition_rating;

                myCompMarkers.push(tempMarker);
                compDistance.push(element.distance);
            });
            myCompMarkers.sort((a, b) => b.condition_rating - a.condition_rating);

            for (let i = 0; i < myCompMarkers.length; i++) {
                if (i === 0) {
                    continue;
                }
                let condition_index = myCompMarkers[i].condition_rating;
                myCompMarkers[i].label = `${myCompMarkers[i].label}`;
                myCompMarkers[i].color = `${markerColors[condition_index] ?? '22caab'}`;
            }

            setMyCompMarker(myCompMarkers);

            if (propData.subject_property) {
                // Subject property Comps
                let maxDistance = Math.max(...compDistance);
                sbjMarker.zoom_level = 18;
                if (maxDistance >= 2) {
                    sbjMarker.zoom_level = 14;
                } else if (maxDistance >= 1 && maxDistance <= 1.5) {
                    sbjMarker.zoom_level = 15;
                } else if (maxDistance >= 0.5 && maxDistance <= 1) {
                    sbjMarker.zoom_level = 16;
                } else if (maxDistance >= 0 && maxDistance <= 0.5) {
                    sbjMarker.zoom_level = 17;
                }

                // Subject Comp
                sbjMarker.default_zoom_level = 18;
                if (propData.subject_property.geo_parameters >= 2) {
                    sbjMarker.default_zoom_level = 14;
                } else if (propData.subject_property.geo_parameters >= 1 && propData.subject_property.geo_parameters <= 1.5) {
                    sbjMarker.default_zoom_level = 15;
                } else if (propData.subject_property.geo_parameters >= 0.5 && propData.subject_property.geo_parameters <= 1) {
                    sbjMarker.default_zoom_level = 16;
                } else if (propData.subject_property.geo_parameters >= 0 && propData.subject_property.geo_parameters <= 0.5) {
                    sbjMarker.default_zoom_level = 17;
                }

                // Prepare Markers Data
                sbjMarker.id = -1;
                sbjMarker.name = propData.subject_property.address;
                sbjMarker.position = { lat: propData.subject_property.latitude, lng: propData.subject_property.longitude };
                sbjMarker.is_comp_entry_for_property = 1;
                sbjMarker.label = 'S';

                setSubjectPropertyComp(sbjMarker);
                setUpdate(moment());
            }
        }


        if (propData.current_land_uses) {
            let tempLandUses = [...landUsesIds, ...propData.current_land_uses];
            setLandUsesIds(tempLandUses);
        }
    }, [propData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);

    let handleLandUseCheckbox = (e, i) => {
        let isChecked = e.target.checked;
        let value = parseInt(e.target.value);

        if (isChecked) {
            propData.current_land_uses.forEach((element) => {
                if (element.land_use_id === value) {
                    const updatedCancelLandUses = cancelLandUses.filter(
                        (item) => item !== value
                    );
                    setCancelLandUses(updatedCancelLandUses);
                }
            });

            const updatedLandUsesIds = [
                ...landUsesIds,
                { land_use_id: value, is_of_concern: "" },
            ];
            setLandUsesIds(updatedLandUsesIds);

        } else {
            propData.current_land_uses.forEach((element) => {
                if (element.land_use_id === value) {
                    const updatedCancelLandUses = [...cancelLandUses, value];
                    setCancelLandUses(updatedCancelLandUses);
                }
            });

            const updatedLandUsesIds = landUsesIds.filter(
                (item) => item.land_use_id !== value
            );
            setLandUsesIds(updatedLandUsesIds);
        }

        setUpdate(moment());
    };

    let handleUseConcern = (e, i, id) => {

        for (let j = 0; j < landUsesIds.length; j++) {

            if (landUsesIds[j].land_use_id === id) {
                let landUseObj = landUsesIds[j];
                landUseObj.is_of_concern = e.target.value;
                landUsesIds[j] = landUseObj;
                setLandUsesIds(landUsesIds);
                setUpdate(moment());

            }
        }

    }

    // Handle Step One
    let handleLandUseForm = (e) => {

        let isError = false;
        if (landUsesIds.length === 0) {
            isError = true;
        } else if (landUsesIds.length > 0) {
            for (let i = 0; i < landUsesIds.length; i++) {
                if (landUsesIds[i].is_of_concern === "") {
                    isError = true;
                }

            }
        }

        if (isError) {
            setCustomError("Please select Land Use with Concern option.");
            return false;
        }
        setCustomError("");

        let data = {
            report_order_id: propData.id,
            land_use_data: landUsesIds,
            user_id: userData.user_id,
            cancel_land_uses: cancelLandUses

        }
        setProcess(true);
        PostData("orders/save_report_order_land_uses", data).then(result => {

            toast["success"](result.data.message);
            onHandleLandUsesResponse(2, 'Quality on Subject Street');
            isError = true;
            // setProcess(false);
        }).catch(errors => {
            setProcess(false);
            toast["error"](errors.message);
            isError = true;
        });
    }

    const markerLoadHandler = (label, color = '22caab', is_comp_entry_for_property) => {

        if (is_comp_entry_for_property === 1) {
            color = 'cb310d';
            label = 'S';
        }

        const icon = googleMapCustomMarker(label, color)

        return icon;
    };

    let onToggle = (e) => {

        let isToggledTemp = e.target.checked;
        setIsToggled(isToggledTemp)
        setUpdate(moment());
    };

    return (
        <>
            <Spinner isShow={isProcess} />
            <AvForm onValidSubmit={(e) => handleLandUseForm(e)}>
                <CardBody>
                    <Row>
                        <Col className="col-md-6 text-left">
                            <div className="mb-3">
                                <p className="property_type">Property Type:  <span className="">{subjectProperty ? subjectProperty.property_type_normalized : ""}</span></p>
                            </div>
                            <p>Please examine the aerial map and <span className="">identify the land uses</span> in the immediate area. In urban areas, you can focus on a smaller, loser distance to define the neighborhood. In rural areas, you can look at a wider view</p>
                            <div>
                                <table className="land-uses table table-striped">
                                    <thead >
                                        <tr >
                                            <td width="10%" className="text-center"></td>
                                            <td width="50%">Land Use</td>
                                            <td width="40%">Is this a Concern?</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {landUses && landUses.map(function (data, i) {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                id={"land_use_" + i}
                                                                name="land_use"
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                value={data.id}
                                                                onChange={e => handleLandUseCheckbox(e, i)}
                                                                checked={landUsesIds && landUsesIds.find((o) => o.land_use_id === data.id) ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor={"land_use_" + i}></label>
                                                        </div>
                                                    </td>
                                                    <td>{data.land_use_name}</td>
                                                    <td>

                                                        {(landUsesIds && landUsesIds.find(o => o.land_use_id === data.id)) &&
                                                            <div className="land-use-radio-btn inline">
                                                                <input type="radio" className="btn-check" name={"land_use_" + i} id={"success-outlined_" + i} value="1" onChange={e => handleUseConcern(e, i, data.id)} checked={(landUsesIds && landUsesIds.find(o => o.land_use_id === data.id && o.is_of_concern === "1")) ? true : false} />
                                                                <label className="yes-label" htmlFor={"success-outlined_" + i} >Yes</label>
                                                                <input type="radio" className="btn-check" name={"land_use_" + i} id={"danger-outlined_" + i} value="0" onChange={e => handleUseConcern(e, i, data.id)} checked={(landUsesIds && landUsesIds.find(o => o.land_use_id === data.id && o.is_of_concern === "0")) ? true : false} />
                                                                <label className="no-label" htmlFor={"danger-outlined_" + i}>No</label>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col className="col-md-6">
                            <div className="map-toggle">

                                <label className="toggle-switch" id="display_comps">
                                    <input type="checkbox" checked={isToggled} onChange={e => onToggle(e)} />
                                    <span className="switch" />

                                </label>
                                < UncontrolledTooltip delay={0} placement="auto" target="display_comps">Display the Comps</UncontrolledTooltip>
                            </div>
                            {subjectProperty && subjectProperty.latitude && subjectProperty.longitude &&
                                <Map
                                    className="custom-map-controls"
                                    contextMenu={true}
                                    google={propData.google}
                                    zoom={isToggled ? subjectPropertyComp.zoom_level : subjectPropertyComp.default_zoom_level}
                                    style={mapStyles}
                                    zoomControlOptions={{
                                        position: propData.google.maps.ControlPosition.BOTTOM_RIGHT
                                    }}
                                    mapType="satellite"
                                    initialCenter={
                                        { lat: subjectPropertyComp.position.lat, lng: subjectPropertyComp.position.lng }
                                    }
                                >
                                    {isToggled ?
                                        myCompMarker.map(({ id, name, position, label, color, is_comp_entry_for_property }) => (
                                            is_comp_entry_for_property === 0 ?
                                                <Marker
                                                    key={id}
                                                    title={name}
                                                    position={position}
                                                    label={{ text: label, color: "#fff", className: "map-marker-label" }}
                                                    icon={markerLoadHandler("", color, is_comp_entry_for_property)}
                                                    name={name} >
                                                </Marker >
                                                : <Marker icon={markerLoadHandler("S", "", 1)} />
                                        ))
                                        :
                                        <Marker icon={markerLoadHandler("S", "", 1)} />
                                    }

                                </Map>
                            }

                        </Col>
                    </Row>
                </CardBody>

                <CardFooter>
                    <Row>
                        <Col>
                            <div className="form-error">{customError}</div>
                        </Col>

                        <Col className="text-right">
                            <Button color="danger" outline onClick={() => onHandleLandUsesResponse("cancel")} >
                                Cancel Assignment
                            </Button>

                            <Button color="info">
                                <span className="btn-inner--text">Next</span>&nbsp;&nbsp;
                                <span className="btn-inner--icon">
                                    <i className="fa fa-arrow-circle-right" />
                                </span>
                            </Button>
                        </Col>
                    </Row>

                </CardFooter>
            </AvForm>
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(LandUses);