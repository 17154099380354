import React, { useEffect, useState } from "react";
import { Button, Col, Label, Modal, ModalFooter, Row, UncontrolledTooltip } from "reactstrap";
import Dropzone from 'react-dropzone';
import "./style.scss";
import * as moment from "moment";
import { AvForm } from "availity-reactstrap-validation";
import { PostData as OMSPostData } from "services/PostData";
import { GetData, PostData } from "services/Api.service";
import { toast } from 'react-toastify';
import Spinner from "components/Common/Spinner.js";
import Ul from "components/Ui/Ul";
import Error from "components/Ui/Error";
import { confirmAlert } from 'react-confirm-alert';
import { loggedInUser, checkRoles, sendExceptionEmail, checkRolesWithLevel, createFilesArrayWtFaClasses } from "components/Common/Helpers";
import DragAndDropOrUpload from "views/modules/Common/File-Upload/DragAndDropOrUpload";
import ImageSlider from "./Image-Slider/ImageSlider";
import FilesList from "./File-Upload/FilesList";

const DocumentsUploadModal = ({ propData, uploadCallback = () => { } }) => {

    const userData = loggedInUser();
    let [isProcess, setProcess] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [isOpenModal, setOpenModal] = useState(false);
    let [data, setData] = useState({});
    let [isSubmit, setSubmit] = useState(false);
    let [customError, setCustomError] = useState([]);
    // Budget    
    let [selectedBudgetFiles, setSelectedBudgetFiles] = useState([]);

    // Photos    
    let [photosFileUploadError, setPhotosFileUploadError] = useState("");
    let [selectedPhotosFiles, setSelectedPhotosFiles] = useState([]);

    // Videos    
    let [videoFileUploadError, setVideoFileUploadError] = useState("");
    let [selectedVideoFiles, setSelectedVideoFiles] = useState([]);

    // Inspections    
    let [inspectionsFileUploadError, setInspectionsFileUploadError] = useState("");
    let [selectedInspectionsFiles, setSelectedInspectionsFiles] = useState([]);

    // Plans    
    let [plansFileUploadError, setPlansFileUploadError] = useState("");
    let [selectedPlansFiles, setSelectedPlansFiles] = useState([]);

    // Contracts    
    let [contractFileUploadError, setContractFileUploadError] = useState("");
    let [selectedContractFiles, setSelectedContractFiles] = useState([]);

    // Others    
    let [otherFileUploadError, setOtherFileUploadError] = useState("");
    let [selectedOtherFiles, setSelectedOtherFiles] = useState([]);

    let [uploadedFiles, setUploadedFiles] = useState([]);
    let [uploadedPhotos, setUploadedPhotos] = useState([]);

    let isOpenAddBankAccountModal = React.useRef(false);

    useEffect(() => {
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    let handleToggleModal = (state) => {
        state = !state;
        setOpenModal(state);

        if (state === true) {
            retrievePropertyFiles()
        }

    };

    let handleOpenModal = (e) => {
        clearForm();
        handleToggleModal(isOpenModal);
    }

    let clearForm = () => {
        setSelectedBudgetFiles([]);
        setSelectedPhotosFiles([]);
        setSelectedVideoFiles([]);
        setSelectedInspectionsFiles([]);
        setSelectedPlansFiles([]);
        setSelectedContractFiles([]);
        setSelectedOtherFiles([]);
        setData({});
        setCustomError("");
        setSubmit(false);
    }

    let retrievePropertyFiles = async () => {
        try {
            setProcess(true);
            const response = await GetData(`property/${propData.property_id}/property-files`, {})

            let { uploadedFilesTemp, uploadedPhotosTemp } = await createFilesArrayWtFaClasses(response.data.propertyFiles);

            setUploadedFiles(uploadedFilesTemp);
            setUploadedPhotos(uploadedPhotosTemp);
            setProcess(false);

        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            setProcess(false);
        }
    };

    // Video
    let onDropVideos = (files) => {
        let tempArr = [];
        tempArr = files;
        setSelectedVideoFiles(tempArr);
        setVideoFileUploadError("");
        data.video_files = tempArr;
        setData(data);
        setCustomError("");
        setUpdate(moment());
    };

    let onDropRejectedVideos = (rejectedFiles) => {
        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 100MB.";
        }

        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = "Please upload a Video as MP4, MOV, AVI, MPEG, WMV or MKV.";
        }

        const videoFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setVideoFileUploadError(videoFileUploadError);
        setUpdate(moment());
    };

    let videosFiles = selectedVideoFiles && selectedVideoFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, 'selectedVideoFiles', i)}></i> {file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB
        </li>
    ));

    // Photo
    let onDropPhotos = (files) => {

        let tempArr = [];
        tempArr = files;
        setSelectedPhotosFiles(tempArr);
        setPhotosFileUploadError("");
        data.photo_files = tempArr;
        setData(data);
        setCustomError("");
        setUpdate(moment());
    };

    let onDropRejectedPhotos = (rejectedFiles) => {

        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 15MB.";
        }

        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = "Please upload a PDF containing many photos, or upload individual photos as JPEG or PNG.";
        }

        const photosFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setPhotosFileUploadError(photosFileUploadError);
        setUpdate(moment());
    };

    let photosFiles = selectedPhotosFiles && selectedPhotosFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, 'selectedPhotosFiles', i)}></i> {file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB
        </li>
    ));

    // Inspections
    let onDropInspections = (files) => {

        let tempArr = [];
        tempArr = files;
        setSelectedInspectionsFiles(tempArr);
        setInspectionsFileUploadError("");
        data.inspection_files = tempArr;
        setData(data);
        setCustomError("");
        setUpdate(moment());
    };

    let onDropRejectedInspections = (rejectedFiles) => {

        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 100MB.";
        }

        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = "Please upload a PDF, MP4, AVI, MKV, MPEG, WMV or MOV file only.";
        }

        const inspectionsFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setInspectionsFileUploadError(inspectionsFileUploadError);
        setUpdate(moment());
    };

    let inspectionsFiles = selectedInspectionsFiles && selectedInspectionsFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, 'selectedInspectionsFiles', i)}></i> {file.name} - {(file.size * 1024 * 1024).toFixed(2)} MB
        </li>
    ));

    // Plans
    let onDropPlans = (files) => {

        let tempArr = [];
        tempArr = files;
        setSelectedPlansFiles(tempArr);
        setPlansFileUploadError("");
        data.plan_files = tempArr;
        setData(data);
        setCustomError("");
        setUpdate(moment());
    };

    let onDropRejectedPlans = (rejectedFiles) => {

        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 20MB.";
        }

        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = "Please upload a PDF file only.";
        }

        const plansFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setPlansFileUploadError(plansFileUploadError);
        setUpdate(moment());
    };

    let plansFiles = selectedPlansFiles && selectedPlansFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, 'selectedPlansFiles', i)}></i> {file.name} - {(file.size * 1024 * 1024).toFixed(2)} MB
        </li>
    ));

    // Contracts
    let onDropContracts = (files) => {
        let tempArr = [];
        tempArr = files;
        setSelectedContractFiles(tempArr);
        setContractFileUploadError("");
        data.contract_files = tempArr;
        setData(data);
        setCustomError("");
        setUpdate(moment());
    };

    let onDropRejectedContracts = (rejectedFiles) => {

        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 20MB.";
        }

        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = "Please upload a PDF file only.";
        }

        const contractFileUploadErrorTemp = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setContractFileUploadError(contractFileUploadErrorTemp);
        setUpdate(moment());
    };

    let contractFiles = selectedContractFiles && selectedContractFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, 'selectedContractFiles', i)}></i> {file.name} - {(file.size * 1024 * 1024).toFixed(2)} MB
        </li>
    ));

    // Other Files
    let onDropOthers = (files) => {
        let tempArr = [];
        tempArr = files;
        setSelectedOtherFiles(tempArr);
        setOtherFileUploadError("");
        data.other_files = tempArr;
        setData(data);
        setCustomError("");
        setUpdate(moment());
    };

    let onDropRejectedOthers = (rejectedFiles) => {

        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 20MB.";
        }

        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = "Please upload a PDF file only.";
        }

        const otherFileUploadErrorTemp = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setOtherFileUploadError(otherFileUploadErrorTemp);
        setUpdate(moment());
    };

    let otherFiles = selectedOtherFiles && selectedOtherFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, 'selectedOtherFiles', i)}></i> {file.name} - {(file.size * 1024 * 1024).toFixed(2)} MB
        </li>
    ));


    let deleteFile = (e, fileType, index) => {
        e.preventDefault();
        if (fileType === 'selectedBudgetFile') {
            let selectedBudgetFileTemp = selectedBudgetFiles;
            selectedBudgetFileTemp.splice(index, 1);
            data.budget_files = selectedBudgetFileTemp.length;
            setSelectedBudgetFiles(selectedBudgetFileTemp);
        } else if (fileType === 'selectedPhotosFiles') {
            let selectedPhotosFilesFilesTemp = selectedPhotosFiles;
            selectedPhotosFilesFilesTemp.splice(index, 1);
            data.photo_files = selectedPhotosFilesFilesTemp;
            setSelectedPhotosFiles(selectedPhotosFilesFilesTemp);
        } else if (fileType === 'selectedVideoFiles') {
            let selectedVideoFilesFilesTemp = selectedVideoFiles;
            selectedVideoFilesFilesTemp.splice(index, 1);
            data.video_files = selectedVideoFilesFilesTemp;
            setSelectedVideoFiles(selectedVideoFilesFilesTemp);
        } else if (fileType === 'selectedInspectionsFiles') {
            let selectedInspectionsFilesTemp = selectedInspectionsFiles;
            selectedInspectionsFilesTemp.splice(index, 1);
            data.inspection_files = selectedInspectionsFilesTemp;
            setSelectedInspectionsFiles(selectedInspectionsFilesTemp);
        } else if (fileType === 'selectedPlansFiles') {
            let selectedPlansFilesTemp = selectedPlansFiles;
            selectedPlansFilesTemp.splice(index, 1);
            data.plan_files = selectedPlansFilesTemp;
            setSelectedPlansFiles(selectedPlansFilesTemp);
        } else if (fileType === 'selectedContractFiles') {
            let selectedContractFilesTemp = selectedContractFiles;
            selectedContractFilesTemp.splice(index, 1);
            data.contract_files = selectedContractFilesTemp;
            setSelectedContractFiles(selectedContractFilesTemp);
        } else if (fileType === 'selectedOtherFiles') {
            let selectedOtherFilesTemp = selectedOtherFiles;
            selectedOtherFilesTemp.splice(index, 1);
            data.other_files = selectedOtherFilesTemp;
            setSelectedOtherFiles(selectedOtherFilesTemp);
        }
        setData(data);
        setUpdate(moment());
    };

    let handleUploadDocuments = async (e) => {

        setSubmit(true);
        let params = {};
        params.report_id = propData.report_id;
        params.property_id = propData.property_id;
        params.user_id = userData.user_id;

        let requestDataForm = new FormData();
        if (data.budget_files && data.budget_files.length) {
            for (let i = 0; i < data.budget_files.length; i++) {
                requestDataForm.append('budget_files', data.budget_files[i]);
            }
        }
        if (data.photo_files && data.photo_files.length) {
            for (let i = 0; i < data.photo_files.length; i++) {
                requestDataForm.append('photo_files', data.photo_files[i]);
            }
        }
        if (data.video_files && data.video_files.length) {
            for (let i = 0; i < data.video_files.length; i++) {
                requestDataForm.append('video_files', data.video_files[i]);
            }
        }
        if (data.inspection_files && data.inspection_files.length) {
            for (let i = 0; i < data.inspection_files.length; i++) {
                requestDataForm.append('inspection_files', data.inspection_files[i]);
            }

        }
        if (data.plan_files && data.plan_files.length) {
            for (let i = 0; i < data.plan_files.length; i++) {
                requestDataForm.append('plan_files', data.plan_files[i]);
            }
        }

        if (data.contract_files && data.contract_files.length) {
            for (let i = 0; i < data.contract_files.length; i++) {
                requestDataForm.append('contract_files', data.contract_files[i]);
            }
        }

        if (data.other_files && data.other_files.length) {
            for (let i = 0; i < data.other_files.length; i++) {
                requestDataForm.append('other_files', data.other_files[i]);
            }
        }
        for (var key in params) {
            requestDataForm.append(key, params[key]);
        }

        if ((Object.keys(data).length > 0) && (selectedBudgetFiles.length > 0 || selectedVideoFiles.length > 0 || selectedPhotosFiles.length > 0 || selectedInspectionsFiles.length > 0 || selectedPlansFiles.length > 0 || selectedContractFiles.length > 0 || selectedOtherFiles.length > 0)) {
            try {
                setProcess(true);
                let response = await PostData("orders/save_documents_uploads", requestDataForm)
                retrievePropertyFiles()
                clearForm()
                uploadCallback({ success: true });
                toast["success"](response.message);
                setProcess(false);

            } catch (error) {
                console.log(error);
                await sendExceptionEmail(error, 'DocumentsUploadModal', 'handleUploadDocuments', {});
                toast["error"]("Something went wrong.");
                setProcess(false);
            }
        } else {
            setCustomError("Please upload at least one file.");
        }
    }

    let openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    let addBankAccount = (e) => {
        e.preventDefault();

        isOpenAddBankAccountModal.current = true;
        setUpdate(moment());
    };


    let handleCancel = (fileRow) => {
        let documentData = {
            file_id: fileRow.id
        };
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to delete this file?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Delete',
                    onClick: async () => {
                        try {
                            setProcess(true)
                            let response = await OMSPostData("orders/delete-documents", documentData);
                            retrievePropertyFiles()
                            setProcess(false)
                            toast['success'](response.data.message);
                        } catch (errors) {
                            setProcess(false);
                            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
                            await sendExceptionEmail(errors, 'DocumentsUploadModal', 'handleCancel', {});
                        }
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Cancel',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    }
    let handleSelectedFile = (files) => {
        data.budget_files = files?.selectedFiles;
        setData(data);
        setSelectedBudgetFiles(files?.selectedFiles);
    };

    let checkValueInString = (csvString, valueToCheck) => {
        if (!csvString) {
            return true;
        }
        const valuesArray = csvString.split(',');
        return valuesArray.includes(valueToCheck.trim());
    }

    return (
        <>

            {(((checkRoles(['REVIEWER', 'ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'TRAINING_ADMINISTRATOR', 'ACCOUNT_REP']))) || (userData.is_RV_user === false && userData.is_client === true)) &&
                <>

                    <Button id="documents_upload" color="success" size="sm" type="button" onClick={e => handleOpenModal(e)}>
                        <i className="fas fa-upload" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target="documents_upload" > Documents Upload </UncontrolledTooltip>
                </>
            }

            {isOpenModal &&
                <Modal className="modal-dialog-centered documents-modal documents-upload" style={{ maxWidth: "80%" }} isOpen={isOpenModal} toggle={() => handleToggleModal(isOpenModal)}>
                    <Spinner isShow={isProcess} />
                    <AvForm onValidSubmit={(e) => handleUploadDocuments(e)} >
                        <div className="modal-header modal-header-colored">
                            <h3 className="modal-title text-center w-100">
                                Documents
                            </h3>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>

                        <div className="modal-body pb-0 pt-3">
                            <Row>
                                <Col lg="2">
                                    <h3 className="modal-title" id="reassignModalLabel">
                                        Uploaded
                                        <hr className="mb-3 mt-2" />
                                    </h3>

                                    <Row className="uploaded-docs-col">
                                        {uploadedFiles.length ?
                                            uploadedFiles.map(function (fileRow, i) {
                                                return (
                                                    <Col key={i} lg="12" className="p-2">
                                                        <i className={`${fileRow.iClassName} fa-2x`}></i><span className="cursor-pointer" style={{ verticalAlign: 'super' }} onClick={() => openInNewTab(fileRow.location)}> {fileRow.property_file_type.file_type_name} - {moment(fileRow.uploaded).format("MMM DD, YYYY")}</span>
                                                        {
                                                            (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 2)) &&
                                                            <i className="fa fa-solid fa-trash ml-2 text-danger" style={{ verticalAlign: 'super' }} onClick={() => handleCancel(fileRow)}></i>
                                                        }
                                                    </Col>
                                                );
                                            }) : null
                                        }

                                        {uploadedPhotos.length ?

                                            <>
                                                <Col lg="12" className="p-2">
                                                    <i className="fa fa-image text-danger fa-2x"></i><span className="cursor-pointer" style={{ verticalAlign: 'super' }} onClick={(e) => addBankAccount(e)}> Images </span>
                                                    {
                                                        (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 2)) &&
                                                        <i className="fa fa-solid fa-trash ml-2 text-danger" style={{ verticalAlign: 'super' }} onClick={() => handleCancel({ id: uploadedPhotos[0].id })}></i>
                                                    }
                                                </Col>
                                            </> : null
                                        }

                                        {!uploadedFiles.length && !uploadedPhotos.length &&
                                            <Col lg="12" className="text-center text-muted"> No documents available!</Col>
                                        }
                                    </Row>
                                </Col>
                                <Col lg="10">
                                    <h3 className="modal-title" id="reassignModalLabel">
                                        Upload New Documents
                                        <hr className="mb-3 mt-2" />
                                    </h3>
                                    <Row className="drop-zone-area">
                                        {checkValueInString(propData.documents, 'budgets') &&
                                            <Col className="col-md-4">
                                                <DragAndDropOrUpload label="Budgets" accept="pdf,excel,word" fileUploadCB={(files) => handleSelectedFile(files)} maxFileSize={2} maxFiles={3} />
                                            </Col>
                                        }
                                        {checkValueInString(propData.documents, 'inspections') &&
                                            <Col className="col-md-4">
                                                <Label className="">Inspections</Label>
                                                <Dropzone
                                                    onDrop={onDropInspections}
                                                    onDropRejected={onDropRejectedInspections}
                                                    accept="application/pdf,video/mp4,video/quicktime,video/avi,video/x-matroska,video/mpeg,video/x-ms-wmv"
                                                    minSize={0}
                                                    maxSize={1048576 * 100} //1048576 equal to 1 MB
                                                    maxFiles={3}
                                                >
                                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                                        return (
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <h2 className="drag-drop-text">Click to Select or Drag it Here.</h2>
                                                                <Ul propLiContent={['Upload only PDF, MP4, AVI, MKV, MPEG, WMV or MOV files', 'Maximum size per file is 100MB.', 'Maximum 3 files are allowed.']} />

                                                                <Error errorMessage={inspectionsFileUploadError} />
                                                            </div>
                                                        )
                                                    }
                                                    }
                                                </Dropzone>
                                                <FilesList filesList={inspectionsFiles} />
                                            </Col>
                                        }
                                        {checkValueInString(propData.documents, 'plans') &&
                                            <Col className="col-md-4">
                                                <Label className="">Plans</Label>
                                                <Dropzone
                                                    onDrop={onDropPlans}
                                                    onDropRejected={onDropRejectedPlans}
                                                    accept="application/pdf"
                                                    minSize={0}
                                                    maxSize={1048576 * 20} //1048576 equal to 1 MB
                                                    maxFiles={3}
                                                >
                                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                                        return (
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <h2 className="drag-drop-text">Click to Select or Drag it Here.</h2>
                                                                <Ul propLiContent={['Upload only .PDF files', 'Maximum size per file is 20MB.', 'Maximum 3 files are allowed.']} />

                                                                <Error errorMessage={plansFileUploadError} />
                                                            </div>
                                                        )
                                                    }
                                                    }
                                                </Dropzone>
                                                <FilesList filesList={plansFiles} />
                                            </Col>
                                        }
                                        {checkValueInString(propData.documents, 'photos') &&
                                            <Col className="col-md-4">
                                                <Label className="">Photos</Label>
                                                <Dropzone
                                                    onDrop={onDropPhotos}
                                                    onDropRejected={onDropRejectedPhotos}
                                                    accept="application/pdf,image/jpg,image/jpeg,image/png"
                                                    minSize={0}
                                                    maxSize={1048576 * 15} //1048576 equal to 1 MB
                                                    maxFiles={20}
                                                >
                                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                                        return (
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <h2 className="drag-drop-text">Click to Select or Drag it Here.</h2>
                                                                <Ul propLiContent={['Upload only .PDF, .JPEG, .PNG files.', 'Maximum size per file is 15MB.', 'Maximum 20 files are allowed.']} />

                                                                <Error errorMessage={photosFileUploadError} />
                                                            </div>
                                                        )
                                                    }
                                                    }
                                                </Dropzone>
                                                <FilesList filesList={photosFiles} />
                                            </Col>
                                        }

                                        {checkValueInString(propData.documents, 'videos') &&
                                            <Col className="col-md-4">
                                                <Label className="">Videos</Label>
                                                <Dropzone
                                                    onDrop={onDropVideos}
                                                    onDropRejected={onDropRejectedVideos}
                                                    accept="video/mp4,video/quicktime,video/avi,video/x-matroska,video/mpeg,video/x-ms-wmv"
                                                    minSize={0}
                                                    maxSize={1048576 * 100} //1048576 equal to 1 MB
                                                    maxFiles={2}
                                                >
                                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                                        return (
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <h2 className="drag-drop-text">Click to Select or Drag it Here.</h2>
                                                                <Ul propLiContent={['Upload only .MP4, .MOV, .AVI, .MKV, .MPEG, or .WMV files.', 'Maximum size per file is 100MB.', 'Maximum 2 files are allowed.']} />

                                                                <Error errorMessage={videoFileUploadError} />
                                                            </div>
                                                        )
                                                    }
                                                    }
                                                </Dropzone>
                                                <FilesList filesList={videosFiles} />
                                            </Col>
                                        }
                                        {checkValueInString(propData.documents, 'contracts') &&
                                            <Col className="col-md-4">
                                                <Label className="">Contracts</Label>
                                                <Dropzone
                                                    onDrop={onDropContracts}
                                                    onDropRejected={onDropRejectedContracts}
                                                    accept="application/pdf"
                                                    minSize={0}
                                                    maxSize={1048576 * 20} //1048576 equal to 1 MB
                                                    maxFiles={3}
                                                >
                                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                                        return (
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <h2 className="drag-drop-text">Click to Select or Drag it Here.</h2>
                                                                <Ul propLiContent={['Upload only .PDF files', 'Maximum size per file is 20MB.', 'Maximum 3 files are allowed.']} />

                                                                <Error errorMessage={contractFileUploadError} />
                                                            </div>
                                                        )
                                                    }
                                                    }
                                                </Dropzone>
                                                <FilesList filesList={contractFiles} />
                                            </Col>
                                        }
                                        {checkValueInString(propData.documents, 'other') &&
                                            <Col className="col-md-4">
                                                <Label className="">Other</Label>
                                                <Dropzone
                                                    onDrop={onDropOthers}
                                                    onDropRejected={onDropRejectedOthers}
                                                    accept="application/pdf"
                                                    minSize={0}
                                                    maxSize={1048576 * 20} //1048576 equal to 1 MB
                                                    maxFiles={3}
                                                >
                                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                                        return (
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <h2 className="drag-drop-text">Click to Select or Drag it Here.</h2>
                                                                <Ul propLiContent={['Upload only .PDF files', 'Maximum size per file is 20MB.', 'Maximum 3 files are allowed.']} />

                                                                <Error errorMessage={otherFileUploadError} />
                                                            </div>
                                                        )
                                                    }
                                                    }
                                                </Dropzone>
                                                <FilesList filesList={otherFiles} />
                                            </Col>
                                        }

                                        <Col className="col-md-12">
                                            {isSubmit === true && customError &&
                                                <Error errorMessage={customError} />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <ModalFooter className="pt-0">
                            <Row className="col-md-12">
                                <Col className="text-right">
                                    <Button color="danger" outline onClick={() => handleToggleModal(isOpenModal)}>Cancel</Button>
                                    <Button color="info">Save</Button>
                                </Col>
                            </Row>
                        </ModalFooter>
                    </AvForm>
                </Modal>
            }

            <ImageSlider propHandleModal={isOpenAddBankAccountModal} imageArray={uploadedPhotos} selectedItem={0} />

        </>
    );
};

export default DocumentsUploadModal;